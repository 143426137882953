import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import ApiClient from "../../api/axios.config";
import dayjs, { Dayjs } from "dayjs";
import { SyntheticEvent, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange, SingleInputTimeRangeField } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ApiScheduleDto } from "../../api/schema";
import { getState } from "../../store";

const AdminPanel = () => {

  const { data: schedule, status: statusSchedule } = useQuery({
    queryKey: ["schedule"],
    queryFn: () => ApiClient.schedule.getSchedule().then((res) => res.data)
  });

  const [scheduleForm, setScheduleForm] = useState<ApiScheduleDto[]>([]);

  useEffect(() => {
    if (schedule)
      setScheduleForm(schedule);
  }, [schedule]);

  const { mutate: mutateSave } = useMutation(
    (payload: ApiScheduleDto[]) =>
      ApiClient.schedule.saveSchedule(payload).then((res) => res.data),
    {
      onSuccess: () => {
        getState().setNotification("Schedule saved", "success");
      },
    }
  );

  const handleSubmitSchedule = async (event: SyntheticEvent) => {
    event.preventDefault();
    console.log(scheduleForm);
    if (scheduleForm)
      mutateSave(scheduleForm);
  }


  const handleChangeSchedule = (id: string, value: DateRange<Dayjs>) => {
    let updatedSchedule = scheduleForm?.map(day => {
      if (day.day === id)
        return {
          ...day,
          start: dayjs(value[0]).format("HH:mm"),
          end: dayjs(value[1]).format("HH:mm")
        };
      return day;
    });
    setScheduleForm(updatedSchedule)
  }

  return (
    <Box display='flex' flexDirection='column' gap={2} paddingBottom={4}>
      <Typography variant='h5'>Admin Panel</Typography>
      <Paper sx={{ padding: 4, maxWidth: 440, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" sx={{ marginBottom: 4, alignSelf: 'center' }}>
          Schedule
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction='column' spacing={3} component="form"
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onSubmit={handleSubmitSchedule}
          >
            {schedule?.map((day) =>
              <Stack key={day.day} direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>
                  {day.day}
                </Typography>

                <Stack direction='row' gap={2}>
                  <SingleInputTimeRangeField
                    label="From - To"
                    defaultValue={[
                      dayjs(schedule.find(item => item.day === day.day)?.start, "HH:mm"),
                      dayjs(schedule.find(item => item.day === day.day)?.end, "HH:mm")
                    ]}
                    onChange={(newValue) => handleChangeSchedule(day.day || '', newValue)}
                    format="hh:mm a"
                  />
                </Stack>
              </Stack>
            )}
            <Button type="submit" variant='contained' sx={{ width: 100, padding: 1 }}>Save</Button>
          </Stack>
        </LocalizationProvider>
      </Paper>
    </Box>
  );
};

export default AdminPanel;
