import create, { GetState, SetState, StateCreator, StoreApi } from "zustand";
import { persist } from "zustand/middleware";
import { MainSettings, User, ArchiveMode, createArchiveMode, createMainSettings, createUser } from "./reducer";

export type StoreState = MainSettings & User & ArchiveMode;

export type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>,
  api?: StoreApi<StoreState>
) => T;

const store = (
  set: SetState<StoreState>,
  get: GetState<StoreState>,
  api: StoreApi<StoreState>
) => ({
  ...createMainSettings(set, get),
  ...createUser(set, get),
  ...createArchiveMode(set, get),
});

export const useStore = create<StoreState>()(
  persist(store, {
    name: "store",
    version: 3,
    // State migration
    // migrate: (persistedState) => persistedState,
    // Store persistence in localStorage
    partialize: (state) => ({
      token: state.token,
      user: state.user,
      archiveMode: state.archiveMode,
    }),
  })
);

export const getState = () => useStore.getState();
export const setState = (args: Partial<StoreState>) => useStore.setState(args);
export const subscribe = (args: (state: Partial<StoreState>) => void) =>
  useStore.subscribe(args);
