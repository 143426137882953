import { ReactNode, Suspense } from "react";
import { Box, Container } from "@mui/material";
import Sidebar from "./components/Sidebar";
import NavBar from "./components/NavBar";
import { getState, useStore } from "../store";
import { Redirect, useLocation } from "wouter";
import ConfirmDialog from "../elements/ConfirmDialog";
import ConfirmFormDialog from "../elements/ConfirmFormDialog";

const Layout = ({ children }: { children: ReactNode }) => {
  const token = useStore((state) => state.token);
  const showSidebar = useStore((state) => state.showSidebar);
  const [location, setLocation] = useLocation();

  if (!token) {
    return <Redirect to="login" />;
  } else if (location === "/") {
    setLocation("projects");
  }

  return (
    <Container maxWidth={false} sx={{ display: "flex" }}>
      {showSidebar && <Sidebar />}
      <Container maxWidth={false}
        sx={{ marginTop: 2, padding: "0px!important" }}
      >
        <NavBar />
        <Suspense>
          <Box marginTop={`${getState().sidebar.height}px`}>
            {children}
          </Box>
        </Suspense>
      </Container>
      <ConfirmDialog />
      <ConfirmFormDialog />
    </Container>
  );
};

export default Layout;
