import React, { SyntheticEvent, useEffect, useReducer } from "react";
import { Box, Button, Container, Stack, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { setState, getState, useStore } from "../../store";
import { useLocation } from "wouter";
import ApiClient from "../../api/axios.config";
import { ApiAuthenticationDto } from "../../api/schema";
import { css } from "@emotion/react";

declare const gtag: any;

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Login = () => {
  const token = useStore((state) => state.token);
  const [location, setLocation] = useLocation();
  const { mutate } = useMutation({
    mutationFn: (payload: ApiAuthenticationDto) =>
      ApiClient.auth.authenticate(payload).then((res) => res.data),
  });
  const [formInput, setFormInput] = useReducer(
    (state: ApiAuthenticationDto, newState: Partial<ApiAuthenticationDto>) => ({
      ...state,
      ...newState,
    }),
    {
      username: "",
      password: "",
    }
  );

  useEffect(() => {
    if (token) {
      ApiClient.instance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      setLocation("/projects");
    }
  }, [token]);

  const handleInput = (evt: any) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();
    mutate(formInput, {
      onSuccess: (token) => {
        setState({
          token,
        });
      },
      onError: (error) => {
        // Track Failed Login
        gtag('event', 'login_failed', {
          method: 'form',
          event_category: 'authentication',
          event_label: 'Login Failed',
        });
      }
    });
  };

  return (
    <Container css={styles}>
      <Box component="form" onSubmit={handleSubmitForm}>
        {/*<Typography variant="h5" align="center" gutterBottom>*/}
        {/*  Login*/}
        {/*</Typography>*/}

        <TextField
          label="Login"
          variant="outlined"
          fullWidth
          margin="normal"
          name="username"
          onInput={handleInput}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          name="password"
          onInput={handleInput}
        />

        <Stack
          direction="column"
          justifyContent="space-around"
          mt={2}
          spacing={2}
        >
          <Button size="large" type="submit" variant="outlined">
            Login
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default Login;
