import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { getState, useStore } from "../../store";
import shallow from "zustand/shallow";

const ErrorsSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const errors = useStore((state) => state.errors, shallow);

  useEffect(() => {
    const error = errors?.[0];
    const errorsMessages = error?.response?.data.errors;

    if (errorsMessages?.length) {
      errorsMessages.forEach((message) => {
        enqueueSnackbar(message, { variant: "error" });
      });
      getState().removeError();
    } else if (error) {
      // @ts-ignore
      const errorMessage = error?.response?.data.message || error.message;
      enqueueSnackbar(errorMessage, { variant: "error" });
      getState().removeError();
    }
  }, [errors]);

  return <></>;
};

export default ErrorsSnackbar;
