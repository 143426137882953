import React from "react";
import {Chip, ChipProps} from "@mui/material";
import { ApiStory } from "../api/schema";
import { ChipTypeMap } from "@mui/material/Chip/Chip";

const priorityColor = (
  priority: ApiStory["priority"]
): ChipTypeMap["props"]["color"] => {
  switch (priority) {
    case "Critical":
      return "error";
    case "High":
      return "warning";
    case "Medium":
      return "info";
    case "Low":
      return "success";
    default:
      return "default";
  }
};

const PriorityChip = ({
  priority,
  ...props
}: ChipProps & {
  priority: ApiStory["priority"];
}) => {
  return (
    <Chip
      label={priority}
      color={priorityColor(priority)}
      size="small"
      {...props}
    />
  );
};

export default PriorityChip;
