import React, { SyntheticEvent, useEffect, useReducer, useState } from "react";
import { ApiUser, ApiUserDto } from "../../../api/schema";
import { Box, MenuItem, Stack, TextField } from "@mui/material";
import Modal from "../../../elements/Modal/Modal";
import { usePrevious } from "../../../helpers/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import EditableField from "../../../elements/EditableField";
import { useStore } from "../../../store";
import Avatar from "../../../elements/Avatar";

const ViewUser = ({
  open,
  user,
  onClose,
}: {
  open: boolean;
  user: ApiUser;
  onClose: () => void;
}) => {
  const {
    userId,
    name,
    lastName,
    email,
    username,
    role,
    active,
    employmentStatus,
    created,
    updated,
    enabled,
    authorities,
    accountNonExpired,
    accountNonLocked,
    credentialsNonExpired,
  } = user;
  const currentUserRole = useStore((state) => state.user?.role);

  const queryClient = useQueryClient();
  const { mutate, status: updateUserStatus } = useMutation(
    (payload: ApiUserDto) => ApiClient.users.updateUser(userId, payload)
  );

  const [waitBeforeClose, setWaitBeforeClose] = useState(false);
  const previousStatus = usePrevious(updateUserStatus);

  const [formInput, setFormInput] = useReducer(
    (state: ApiUserDto, newState: Partial<ApiUserDto>) => ({
      ...state,
      ...newState,
    }),
    {
      name,
      lastName,
      email,
      username,
      role,
      active,
      employmentStatus,
    }
  );

  useEffect(() => {
    if (
      previousStatus === "loading" &&
      updateUserStatus === "success" &&
      waitBeforeClose
    ) {
      onClose();
    }
  }, [previousStatus, updateUserStatus, waitBeforeClose]);

  const handleCloseModal = () => {
    setWaitBeforeClose(true);
    if (updateUserStatus !== "loading") {
      onClose();
    }
  };

  const handleInput = (evt: SyntheticEvent<any>, withSubmit?: boolean) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });

    if (withSubmit) {
      mutate(
        { ...formInput, [name]: newValue },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
          },
        }
      );
    }
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();

    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      modalWidth={500}
    >
      <Box component="form" onSubmit={handleSubmitForm}>
        <Stack direction="row" spacing={5}>
          <Box sx={{ flex: "1 1 70%" }}>
            <EditableField
              label={null}
              disabled={currentUserRole !== "Admin"}
              fieldProps={{ name: "name" }}
              fontSize={1.5}
              content={formInput.name}
              onInput={handleInput}
              onBlur={handleSubmitForm}
            />

            <Box marginTop={2}>
              <EditableField
                label="Last name"
                disabled={currentUserRole !== "Admin"}
                fieldProps={{ name: "lastName" }}
                typographyProps={{
                  variant: "body2",
                }}
                fontSize={1}
                content={formInput.lastName}
                onInput={handleInput}
                onBlur={handleSubmitForm}
              />
            </Box>

            <Box marginTop={2}>
              <EditableField
                label="Email"
                disabled={currentUserRole !== "Admin"}
                fieldProps={{ name: "email" }}
                typographyProps={{
                  variant: "body2",
                }}
                fontSize={1}
                content={formInput.email}
                onInput={handleInput}
                onBlur={handleSubmitForm}
              />
            </Box>

            <Box marginTop={2}>
              <EditableField
                label="Username"
                disabled={currentUserRole !== "Admin"}
                fieldProps={{ name: "username" }}
                typographyProps={{
                  variant: "body2",
                }}
                fontSize={1}
                multiline={false}
                content={formInput.username}
                onInput={handleInput}
                onBlur={handleSubmitForm}
              />
            </Box>

            {/* {currentUserRole === "Admin" && (
              <Box marginTop={2}>
                <EditableField
                  label="Password"
                  disabled={currentUserRole !== "Admin"}
                  fieldProps={{
                    name: "password",
                    type: "password",
                  }}
                  typographyProps={{
                    variant: "body2",
                  }}
                  textContent="Change password"
                  multiline={false}
                  fontSize={1}
                  content={formInput.password}
                  onInput={handleInput}
                  onBlur={handleSubmitForm}
                />
              </Box>
            )} */}

            <TextField
              select
              variant="standard"
              label="Role"
              name="role"
              disabled={currentUserRole !== "Admin"}
              defaultValue="Developer"
              value={formInput.role}
              onChange={(evt) => handleInput(evt, true)}
              fullWidth
              margin="normal"
            >
              {["Admin", "Manager", "Developer"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              disabled={currentUserRole !== "Admin"}
              variant="standard"
              label="Employment Status"
              name="employmentStatus"
              defaultValue="FullTime"
              value={formInput.employmentStatus}
              onChange={(evt) => handleInput(evt, true)}
              fullWidth
              margin="normal"
            >
              {["FullTime", "Contractor"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              flex: "1 0 30%",
              mr: "40px!important",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              name={name}
              lastName={lastName}
              sx={{
                width: 160,
                height: 160,
              }}
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ViewUser;
