import { useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { Route, Switch, useLocation } from "wouter";
import Layout from "./Layout/Layout";
import Login from "./pages/Login/Login";
import NestedRoutes from "./elements/NestedRoutes";
import Projects from "./pages/Projects/Projects";
import Tasks from "./pages/Tasks/Tasks";
import Stories from "./pages/Stories/Stories";
import Users from "./pages/Users/Users";
import Profile from "./pages/Profile/Profile";
import ApiClient from "./api/axios.config";
import { setState, useStore } from "./store";
import NotificationSnackbar from "./elements/Notifications/NotificationSnackbar";
import ErrorsSnackbar from "./elements/Notifications/ErrorsSnackbar";
import Finance from "./pages/Finance/Finance";
import Dashboard from "./pages/Dashboard/Dashboard";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Unavailable from "./Layout/Unavailable";
import dayjs from "dayjs";
import { ApiScheduleDto } from "./api/schema";

const App = () => {
  const [location, setLocation] = useLocation();

  useQuery({
    queryKey: ["profile"],
    enabled: location !== "/login",
    queryFn: () => ApiClient.profile.getProfile().then((res) => res.data),
    onSuccess: (data) => {
      setState({ user: data });
    },
  });

  const token = useStore((state) => state.token);
  const user = useStore((state) => state.user);

  const { data: schedule } = useQuery({
    queryKey: ["schedule"],
    enabled: token != null,
    queryFn: () => ApiClient.schedule.getSchedule().then(res => res.data),
  });

  const checkSchedule = (schedule: ApiScheduleDto[]) => {
    if (user.role === 'Admin')
      return true;
    const currentDaySchedule = schedule.find(item => item.day == dayjs().format("dddd").toUpperCase());
    if (dayjs().isAfter(dayjs(currentDaySchedule?.start, "HH:mm")) && dayjs().isBefore(dayjs(currentDaySchedule?.end, "HH:mm"))) {
      return true;
    }
    else {
      return false;
    }
  }

  if (token) {
    if (schedule) {
      if (!checkSchedule(schedule)) {
        return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Unavailable />
          </ThemeProvider>
        );
      }
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationSnackbar />
          <ErrorsSnackbar />
          <NestedRoutes base="/" Wrap={Layout}>
            <Switch>
              <Route path="rewards" component={Finance} />
              <Route path="rewards/:projectId" component={Finance} />
              <Route path="projects" component={Projects} />
              <Route path="stories" component={Stories} />
              <Route path="stories/:projectId" component={Stories} />
              <Route path="tasks" component={Tasks} />
              <Route path="tasks/:storyId" component={Tasks} />
              <Route path="dashboard" component={Dashboard} />
              <Route path="users" component={Users} />
              <Route path="users/:userId" component={Users} />
              <Route path="profile" component={Profile} />
              <Route path="admin-panel" component={AdminPanel} />
            </Switch>
          </NestedRoutes>
          <Route path="/login" component={Login} />
          <ReactQueryDevtools />
        </ThemeProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};

export default App;

