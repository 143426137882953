import React, { SyntheticEvent, useReducer, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  Stack,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Modal from "../../../elements/Modal/Modal";
import ApiClient from "../../../api/axios.config";
import { ApiProjectDto, ApiUser } from "../../../api/schema";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewProject = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });
  const { mutate } = useMutation((payload: ApiProjectDto) =>
    ApiClient.projects.createProject(payload)
  );
  const [formInput, setFormInput] = useReducer(
    (state: ApiProjectDto, newState: Partial<ApiProjectDto>) => ({
      ...state,
      ...newState,
    }),
    {
      name: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      teamLeadUsername: "",
      type: "Regular",
      priority: "",
    }
  );

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();
    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["projects"] });
        onClose();
      },
    });
  };

  const [selectedTeamLead, setSelectedTeamLead] = useState<ApiUser | null>(null);
  const [teamLeadInputValue, setTeamLeadInputValue] = useState("");

  return (
    <Modal open={open} onClose={onClose} modalWidth={400}>
      <Box component="form" onSubmit={handleSubmitForm}>
        <Typography variant="h5" align="center" gutterBottom>
          Create new project
        </Typography>

        <TextField
          label="Project name"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          name="name"
          onInput={handleInput}
        />
        <TextField
          label="Description"
          variant="outlined"
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
          name="description"
          onInput={handleInput}
        />
        <Box sx={{ marginTop: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Due date"
              value={dayjs(formInput.dueDate)}
              minDate={dayjs()}
              onChange={(date) =>
                setFormInput({ dueDate: date?.format("YYYY-MM-DD") })
              }
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <Autocomplete
          sx={{ marginTop: 2 }}
          options={users || []}

          value={selectedTeamLead}
          onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
            setSelectedTeamLead(newValue);
            setFormInput({ ["teamLeadUsername"]: newValue?.username });
          }}

          inputValue={teamLeadInputValue}
          onInputChange={(event, newValue) => {
            setTeamLeadInputValue(newValue);
          }}
          fullWidth
          getOptionLabel={(option) => option.username}
          renderInput={(params) => {
            return <TextField label='Team Lead' {...params} />;
          }}
        />
        <TextField
          select
          required
          label="Priority"
          name="priority"
          value={formInput.priority}
          onChange={handleInput}
          fullWidth
          margin="normal"
        >
          <MenuItem value={"Low"}>
            Low
          </MenuItem>
          <MenuItem value={"Medium"}>
            Medium
          </MenuItem>
          <MenuItem value={"High"}>
            High
          </MenuItem>
          <MenuItem value={"Critical"}>
            Critical
          </MenuItem>
        </TextField>
        <Stack
          direction="column"
          justifyContent="space-around"
          mt={2}
          spacing={2}
        >
          <Button size="large" type="submit">
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default NewProject;
