/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiUserDto {
  /**
   * @minLength 2
   * @maxLength 100
   * @pattern ^[A-Z][a-z]*
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 100
   * @pattern ^[A-Z][a-z]*
   */
  lastName: string;
  email: string;
  /**
   * @minLength 2
   * @maxLength 100
   */
  username: string;
  /** @pattern Admin|Manager|Developer */
  role: string;
  active?: boolean;
  /** @pattern FullTime|Contractor */
  employmentStatus: string;
}

export interface ApiAttachment {
  version: number;
  attachmentId: number;
  s3Id: string;
  name: string;
  created: string;
  updated: string;
}

export interface ApiRequest {
  requestId: number;
  requestType: "JOIN" | "LEAD" | "ASSIGN_STORY" | "ASSIGN_TASK";
  motivation: string;
  userId: number;
  userName: string;
  projectId: number;
  projectName: string;
  storyId?: number;
  storyName?: string;
  taskId?: number;
  taskName?: string;
  created: string;
}

export interface ApiComment {
  version: number;
  commentId: number;
  text: string;
  createdBy: ApiUser;
  created: string;
  updated: string;
}

export interface ApiScheduleDto {
  day: string;
  start: string;
  end: string;
}

export interface ApiGrantedAuthority {
  authority?: string;
}

export interface ApiProject {
  version: number;
  projectId: number;
  name: string;
  stories: ApiStory[];
  description: string;
  dueDate: string;
  status: "TODO" | "IN_PROGRESS" | "CODE_REVIEW" | "DONE";
  createdBy: ApiUser;
  owner: ApiUser;
  teamLead?: ApiUser;
  comments?: ApiComment[];
  followers?: ApiUser[];
  requestedToJoin?: ApiUser[];
  requestedToLead?: ApiUser[];
  type: "Regular" | "OpenToAll";
  priority: "Low" | "Medium" | "High" | "Critical";
  started: boolean;
  attachments?: ApiAttachment[];
  archived: boolean;
  finished: boolean;
  budget?: number;
  reserveBudgetPercentage?: number;
  reserveBudget?: number;
  availableBudget?: number;
  projectManagerCompensationPercentage?: number;
  projectManagerCompensation?: number;
  created: string;
  updated: string;
}

export interface ApiStory {
  version: number;
  storyId: number;
  title: string;
  tasks: ApiTask[];
  description: string;
  dueDate: string;
  status: "TODO" | "IN_PROGRESS" | "CODE_REVIEW" | "DONE";
  createdBy: ApiUser;
  assignedUser?: ApiUser;
  comments: ApiComment[];
  followers: ApiUser[];
  requestedToBeAssigned?: ApiUser[];
  project: ApiProject;
  type: "Regular" | "OpenToAll";
  priority: "Low" | "Medium" | "High" | "Critical";
  attachments?: ApiAttachment[];
  archived: boolean;
  unforeseen: boolean;
  bonus: boolean;
  weightPercentage?: number;
  verifiedWeightPercentage?: number;
  potentialReward?: number;
  created: string;
  updated: string;
}

export interface ApiTask {
  version: number;
  taskId: number;
  title: string;
  description: string;
  dueDate: string;
  status: "TODO" | "IN_PROGRESS" | "CODE_REVIEW" | "DONE";
  createdBy: ApiUser;
  assignedUser?: ApiUser;
  story: ApiStory;
  comments: ApiComment[];
  followers: ApiUser[];
  requestedToBeAssigned?: ApiUser[];
  type: "Regular" | "OpenToAll";
  priority: "Low" | "Medium" | "High" | "Critical";
  attachments?: ApiAttachment[];
  archived: boolean;
  unforeseen: boolean;
  estimatedHours?: number;
  potentialReward?: number;
  developerSubmittedHours?: number;
  projectManagerApproved?: boolean;
  adjustedHours?: number;
  actualReward?: number;
  actualRewardWithBonus?: number;
  created: string;
  updated: string;
}

export interface ApiUser {
  version: number;
  userId: number;
  name: string;
  lastName: string;
  email: string;
  username: string;
  role: "Admin" | "Manager" | "Developer";
  active: boolean;
  employmentStatus: "FullTime" | "Contractor";
  projectsRequestedToJoin?: ApiProject[];
  projectsRequestedToLead?: ApiProject[];
  storiesRequestedToBeAssigned?: ApiStory[];
  tasksRequestedToBeAssigned?: ApiTask[];
  followingProjects?: ApiProject[];
  followingStories?: ApiStory[];
  followingTasks?: ApiTask[];
  created: string;
  updated: string;
  enabled?: boolean;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  authorities?: ApiGrantedAuthority[];
}

export interface ApiTaskDto {
  /**
   * @minLength 2
   * @maxLength 100
   */
  title: string;
  /**
   * @minLength 2
   * @maxLength 15000
   */
  description: string;
  /** @format date */
  dueDate: string;
  assignedUserUsername?: string;
  /** @pattern Regular|OpenToAll */
  type: string;
  /** @pattern Low|Medium|High|Critical */
  estimatedHours?: number;
  potentialReward?: number;
  priority: string;
  /** @format int64 */
  storyId: number;
}

export interface ApiStoryDto {
  /**
   * @minLength 2
   * @maxLength 100
   */
  title: string;
  /**
   * @minLength 2
   * @maxLength 15000
   */
  description: string;
  /** @format date */
  dueDate: string;
  assignedUserUsername?: string;
  /** @pattern Regular|OpenToAll */
  type: string;
  /** @pattern Low|Medium|High|Critical */
  priority: string;
  /** @format int64 */
  projectId: number;
}

export interface ApiProjectDto {
  /**
   * @minLength 2
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 2
   * @maxLength 15000
   */
  description: string;
  /** @format date */
  dueDate: string;
  teamLeadUsername?: string;
  /** @pattern Regular|OpenToAll */
  type: string;
  /** @pattern Low|Medium|High|Critical */
  priority: string;
}

export interface ApiAuthenticationDto {
  username?: string;
  password?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://api.extra-bounty.com/api" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://api.extra-bounty.com/api
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  users = {
    /**
     * No description
     *
     * @tags user-controller
     * @name GetUser
     * @request GET:/users/{userId}
     */
    getUser: (userId: number, params: RequestParams = {}) =>
      this.request<ApiUser, any>({
        path: `/users/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateUser
     * @request PUT:/users/{userId}
     */
    updateUser: (userId: number, data: ApiUserDto, params: RequestParams = {}) =>
      this.request<ApiUser, any>({
        path: `/users/${userId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteUser
     * @request DELETE:/users/{userId}
     */
    deleteUser: (userId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${userId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ListAllUsers
     * @request GET:/users
     */
    listAllUsers: (params: RequestParams = {}) =>
      this.request<ApiUser[], any>({
        path: `/users`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name AddNewUser
     * @request POST:/users
     */
    addNewUser: (data: ApiUserDto, params: RequestParams = {}) =>
      this.request<ApiUser, any>({
        path: `/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ListAllUsersUsernames
     * @request GET:/users/names
     */
    listAllUsersUsernames: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/users/names`,
        method: "GET",
        ...params,
      }),
  };
  tasks = {
    /**
     * No description
     *
     * @tags task-controller
     * @name UpdateTask
     * @request PUT:/tasks/{taskId}
     */
    updateTask: (taskId: number, data: ApiTaskDto, params: RequestParams = {}) =>
      this.request<ApiTask, any>({
        path: `/tasks/${taskId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task-controller
     * @name DeleteTask
     * @request DELETE:/tasks/{taskId}
     */
    deleteTask: (taskId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tasks/${taskId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags task-controller
     * @name UpdateStatus
     * @request PUT:/tasks/{taskId}/status/{status}
     */
    updateStatus: (taskId: number, status: string, hoursSpent?: number) =>
      this.request<ApiTask, any>({
        path: `/tasks/${taskId}/status/${status}`,
        method: "PUT",
        query: { hoursSpent: hoursSpent },
      }),

    /**
     * No description
     *
     * @tags task-controller
     * @name ListAllTasks
     * @request GET:/tasks
     */
    listAllTasks: (
      query?: {
        /** @format int64 */
        storyId?: number;
        archived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiTask[], any>({
        path: `/tasks`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task-controller
     * @name CreateTask
     * @request POST:/tasks
     */
    createTask: (data: ApiTaskDto, params: RequestParams = {}) =>
      this.request<ApiTask, any>({
        path: `/tasks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags task-controller
     * @name AddCommentToTask
     * @request POST:/tasks/{taskId}/comments
     */
    addCommentToTask: (taskId: number, text: string) =>
      this.request<ApiTask, any>({
        path: `/tasks/${taskId}/comments`,
        method: "POST",
        body: { text: text },
        type: ContentType.Json,
      }),
    requestToJoin: (taskId: number, userId: number) =>
      this.request({
        path: `/tasks/${taskId}/join`,
        method: "POST",
        body: { userId: userId },
        type: ContentType.Json,
      }),
    requestToAssign: (taskId: number, userId: number) =>
      this.request({
        path: `/tasks/${taskId}/beAssigned`,
        method: "POST",
        body: { userId: userId },
        type: ContentType.Json,
      }),
    assign: (
      taskId: number,
      userId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiTask, any>({
        path: `/tasks/${taskId}/assign/${userId}`,
        method: "POST",
        query: query,
        ...params,
      }),
    unassign: (
      taskId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiTask, any>({
        path: `/tasks/${taskId}/unassign`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  stories = {
    /**
     * No description
     *
     * @tags story-controller
     * @name UpdateStory
     * @request PUT:/stories/{storyId}
     */
    updateStory: (storyId: number, data: ApiStoryDto, params: RequestParams = {}) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name DeleteStory
     * @request DELETE:/stories/{storyId}
     */
    deleteStory: (storyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/stories/${storyId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name UpdateStatus1
     * @request PUT:/stories/{storyId}/status/{status}
     */
    updateStatus1: (storyId: number, status: string, params: RequestParams = {}) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}/status/${status}`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name ListAllStories
     * @request GET:/stories
     */
    listAllStories: (
      query?: {
        projectId?: number;
        archived?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiStory[], any>({
        path: `/stories`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name CreateStory
     * @request POST:/stories
     */
    createStory: (data: ApiStoryDto, params: RequestParams = {}) =>
      this.request<ApiStory, any>({
        path: `/stories`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name AddTaskToStory
     * @request POST:/stories/{storyId}/tasks/{taskId}
     */
    addTaskToStory: (storyId: number, taskId: number, params: RequestParams = {}) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}/tasks/${taskId}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags story-controller
     * @name AddCommentToStory
     * @request POST:/stories/{storyId}/comments
     */
    addCommentToStory: (storyId: number, text: string) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}/comments`,
        method: "POST",
        body: { text: text },
        type: ContentType.Json,
      }),
    requestToJoin: (storyId: number, userId: number) =>
      this.request({
        path: `/stories/${storyId}/join`,
        method: "POST",
        body: { userId: userId },
        type: ContentType.Json,
      }),
    requestToAssign: (storyId: number, userId: number) =>
      this.request({
        path: `/stories/${storyId}/beAssigned`,
        method: "POST",
        body: { userId: userId },
        type: ContentType.Json,
      }),
    assign: (
      storyId: number,
      userId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}/assign/${userId}`,
        method: "POST",
        query: query,
        ...params,
      }),
    unassign: (
      storyId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiStory, any>({
        path: `/stories/${storyId}/unassign`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  projects = {
    /**
     * No description
     *
     * @tags project-controller
     * @name UpdateProject
     * @request PUT:/projects/{projectId}
     */
    updateProject: (projectId: number, data: ApiProjectDto, params: RequestParams = {}) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name DeleteProject
     * @request DELETE:/projects/{projectId}
     */
    deleteProject: (projectId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/projects/${projectId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name ListAllProjects
     * @request GET:/projects
     */
    listAllProjects: (archived?: boolean) =>
      this.request<ApiProject[], any>({
        path: `/projects`,
        method: "GET",
        query: { archived: archived },
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name CreateProject
     * @request POST:/projects
     */
    createProject: (data: ApiProjectDto, params: RequestParams = {}) =>
      this.request<ApiProject, any>({
        path: `/projects`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name StartProject
     * @request POST:/projects/{projectId}/start
     */
    startProject: (projectId: number, params: RequestParams = {}) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/start`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name FinishProject
     * @request GET:/projects/{projectId}/finish
     */
    finishProject: (projectId: number, data: Record<string, Record<string, object>> | null, params: RequestParams = {}) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/finish`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    archiveProject: (projectId: number, params: RequestParams = {}) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/archive`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name GetPanel
     * @request GET:/projects/{projectId}/panel
     */
    getPayout: (projectId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/projects/${projectId}/payout`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name GetAttachment2
     * @request GET:/projects/{projectId}/attachments/{attachmentId}
     */
    getPanel: (projectId: number, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/projects/${projectId}/panel`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name SavePanel
     * @request POST:/projects/{projectId}/panel
     */
    savePanel: (projectId: number, data: Record<string, Record<string, object>>, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/projects/${projectId}/panel`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name AddCommentToProject
     * @request POST:/projects/{projectId}/comments
     */
    addCommentToProject: (projectId: number, text: string) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/comments`,
        method: "POST",
        body: { text: text },
        type: ContentType.Json,
      }),

    requestToLead: (projectId: number, motivation: string) =>
      this.request({
        path: `/projects/${projectId}/lead`,
        method: "POST",
        body: { motivation: motivation },
        type: ContentType.Json,
      }),

    requestToJoin: (projectId: number, motivation: string) =>
      this.request({
        path: `/projects/${projectId}/join`,
        method: "POST",
        body: { motivation: motivation },
        type: ContentType.Json,
      }),

    assign: (
      projectId: number,
      userId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/assign/${userId}`,
        method: "POST",
        query: query,
        ...params,
      }),
    unassign: (
      projectId: number,
      query: {
        role: string; // "TeamLead" | "Follower" | "Owner" | "AssignedUser";
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ApiProject, any>({
        path: `/projects/${projectId}/unassign`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  attachments = {
    /**
     * No description
     *
     * @tags project-controller
     * @name listAttachments
     * @request GET:/projects/{projectId}/attachments
     */
    listAttachments: (type: string, id: number) =>
      this.request({
        path: `/${type}/${id}/attachments`,
        method: "GET"
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name getAttachment
     * @request GET:/projects/{projectId}/attachments
     */
    getAttachment: (type: string, id: number, attachmentId: string) =>
      this.request({
        path: `/${type}/${id}/attachments/${attachmentId}`,
        method: "GET"
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name UploadAttachment
     * @request POST:/projects/{projectId}/attachments
     */
    uploadAttachment: (
      type: string,
      id: number,
      data: {
        name: string;
        file: File;
      }) =>
      this.request<ApiProject, any>({
        path: `/${type}/${id}/attachments`,
        method: "POST",
        body: data,
        type: ContentType.FormData
      }),

    /**
     * No description
     *
     * @tags project-controller
     * @name UploadAttachment
     * @request POST:/projects/{projectId}/attachments
     */
    deleteAttachment: (type: string, id: number, attachmentId: number) =>
      this.request({
        path: `/${type}/${id}/attachments/${attachmentId}`,
        method: "DELETE"
      }),
  };
  schedule = {
    getSchedule: (params: RequestParams = {}) =>
      this.request<ApiScheduleDto[], any>({
        path: `/schedule`,
        method: "GET",
        ...params,
      }),
    /**
     * No description
     *
     * @tags schedule-controller
     * @name SaveSchedule
     * @request POST:/schedule
     */
    saveSchedule: (data: ApiScheduleDto[], params: RequestParams = {}) =>
      this.request<ApiScheduleDto[], any>({
        path: `/schedule`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      })
  };
  requests = {
    getRequests: () =>
      this.request<ApiRequest[]>({
        path: `/profile/requests`,
        method: "GET"
      }),

    approve: (requestId: number) =>
      this.request({
        path: `/requests/${requestId}/approve`,
        method: "POST"
      }),

    deny: (requestId: number) =>
      this.request({
        path: `/requests/${requestId}/deny`,
        method: "POST"
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags user-profile-controller
     * @name GetProfile
     * @request GET:/profile
     */
    getProfile: (params: RequestParams = {}) =>
      this.request<ApiUser, any>({
        path: `/profile`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-profile-controller
     * @name UpdateProfile
     * @request PUT:/profile
     */
    updateProfile: (data: ApiUserDto, params: RequestParams = {}) =>
      this.request<ApiUser, any>({
        path: `/profile`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
    changeUserPassword: (
      newPassword: string,
      oldPassword: string,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/profile/updatePassword`,
        method: "POST",
        body: {
          newPassword: newPassword,
          oldPassword: oldPassword
        },
        ...params,
      }),
  };
  comments = {
    /**
     * No description
     *
     * @tags comment-controller
     * @name UpdateComment
     * @request PUT:/comments
     */
    updateComment: (commentId: number, text: string) =>
      this.request<ApiComment, any>({
        path: `/comments/${commentId}`,
        method: "PUT",
        body: {
          text: text
        },
        type: ContentType.Json,
      }),

    /**
     * No description
     *
     * @tags comment-controller
     * @name DeleteComment
     * @request DELETE:/comments/{commentId}
     */
    deleteComment: (commentId: number) =>
      this.request<void, any>({
        path: `/comments/${commentId}`,
        method: "DELETE",
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags authentication-controller
     * @name Authenticate
     * @request POST:/auth/login
     */
    authenticate: (data: ApiAuthenticationDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  error = {
    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError
     * @request GET:/error
     */
    handleError: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError3
     * @request PUT:/error
     */
    handleError3: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "PUT",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError2
     * @request POST:/error
     */
    handleError2: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError5
     * @request DELETE:/error
     */
    handleError5: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError6
     * @request OPTIONS:/error
     */
    handleError6: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "OPTIONS",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError1
     * @request HEAD:/error
     */
    handleError1: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "HEAD",
        ...params,
      }),

    /**
     * No description
     *
     * @tags custom-error-controller
     * @name HandleError4
     * @request PATCH:/error
     */
    handleError4: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/error`,
        method: "PATCH",
        ...params,
      }),
  };
}
