import React from "react";
import { IconButton, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStore } from "../store";

const DotsMenu = ({
  menuItems,
  buttonSx,
}: {
  menuItems: { name: string; onClick?: () => void }[];
  buttonSx?: SxProps<Theme>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const archiveMode = useStore((state) => state.archiveMode);

  return (
    <>
      <IconButton
        onClick={(evt) => {
          evt.stopPropagation();
          setAnchorEl(evt.currentTarget);
        }}
        size="small"
        sx={{
          zIndex: 1,
          ml: "auto",
          border: "1px solid transparent",
          borderRadius: "4px",
          backgroundColor: grey[900],
          color: grey[500],
          ...buttonSx,
          "&:hover": {
            backgroundColor: grey[800],
          },
        }}
        aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item, i) => (
          <MenuItem
            key={i}
            disabled={archiveMode ? true : false}
            onClick={() => {
              setAnchorEl(null);
              if (item.onClick) {
                item.onClick();
              }
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DotsMenu;
