import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { theme } from "../../theme";
import Modal from "./Modal";
import EditableField from "../EditableField";
import {
  ApiProject,
  ApiProjectDto,
  ApiStory,
  ApiStoryDto,
  ApiTask,
  ApiTaskDto,
  ApiUser,
} from "../../api/schema";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import PriorityChip from "../PriorityChip";
import { usePrevious } from "../../helpers/helpers";
import Attachments from "./Attachments";
import ApiClient from "../../api/axios.config";
import { getState, useStore } from "../../store";
import Avatar from "../Avatar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmFormDialog from "../ConfirmFormDialog";

const ModalView = ({
  type,
  id,
  project,
  story,
  task,
  open,
  onClose,
  onSubmit,
  onInput,
  formInput,
  onChangeDate,
  createdBy,
  created,
  status,
  panels,
  onClearValue,
  modalType,
}: {
  type: string;
  id: number;
  project: ApiProject;
  story: ApiStory;
  task: ApiTask;
  open: boolean;
  onClose: () => void;
  onSubmit: (evt: SyntheticEvent) => void;
  onInput: (evt: SyntheticEvent) => void;
  formInput: Partial<
    ApiStoryDto & ApiStory & ApiTask & ApiProject & ApiTaskDto & ApiProjectDto
  >;
  onChangeDate: (date: dayjs.Dayjs | null) => void;
  createdBy: ApiUser;
  created: string;
  status: "error" | "idle" | "loading" | "success";
  panels?: {
    el: React.ReactElement;
  }[];
  onClearValue?: ({ name, value }: { name: string; value: string }) => void;
  modalType: "story" | "project" | "task";
}) => {
  const [waitBeforeClose, setWaitBeforeClose] = useState(false);
  const previousStatus = usePrevious(status);

  const queryClient = useQueryClient();

  const [commentInput, setCommentInput] = useState('');
  const [commentInputFocus, setCommentInputFocus] = useState(false);
  const [editingComment, setEditingComment] = useState(0);
  const [editField, setEditField] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [teamLeadInputValue, setTeamLeadInputValue] = useState("");
  const [followersSelectInputValue, setFollowersSelectInputValue] = useState("");

  const [anchorButton, setAnchorButton] = useState<SVGSVGElement | null>(null);
  const openAssignUserPopover = Boolean(anchorButton);

  const store = getState();
  const activeModalType = task || story || project;
  const archiveMode = useStore((state) => state.archiveMode)

  const [filteredUsers, setFilteredUsers] = useState<ApiUser[]>([]);

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => {
      return ApiClient.users.listAllUsers().then((res) => res.data);
    },
    onSuccess: (data) => {
      setFilteredUsers(data.filter(
        (user) =>
          !activeModalType?.followers?.find((follower) => follower.userId === user.userId)
      ));
    }
  });

  const assignUser = (role: string, userId: number | null) => {
    if (userId) {
      if (task) {
        ApiClient.tasks.assign(task.taskId, userId, { role: role }).then(() => updateQuery()); return;
      }
      if (story) {
        ApiClient.stories.assign(story.storyId, userId, { role: role }).then(() => updateQuery()); return;
      }
      if (project) {
        ApiClient.projects.assign(project.projectId, userId, { role: role }).then(() => updateQuery()); return;
      }
    }
  }

  const unassign = (role: string, userId?: number) => {
    if (project)
      ApiClient.projects.unassign(project.projectId, { role: role, userId: userId }).then(() => updateQuery());
    if (story)
      ApiClient.stories.unassign(story.storyId, { role: role }).then(() => updateQuery());
    if (task)
      ApiClient.tasks.unassign(task.taskId, { role: role }).then(() => updateQuery());
  }

  useEffect(() => {
    if (
      previousStatus === "loading" &&
      status === "success" &&
      waitBeforeClose
    ) {
      onClose();
    }
  }, [previousStatus, status, waitBeforeClose, project, editingComment]);

  const handleCloseModal = () => {
    setWaitBeforeClose(true);
    if (status !== "loading") {
      onClose();
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const includeUser = () => {
    if (project?.followers) {
      for (let i = 0; i < project.followers.length; i++) {
        if (project.followers[i].userId === store.user.userId)
          return true;
      }
      return false;
    }
    return false;
  }

  const requestedToJoin = () => {
    if (project) {
      if (project.requestedToJoin) {
        for (let i = 0; i < project.requestedToJoin.length; i++) {
          if (project.requestedToJoin[i].userId === store.user.userId)
            return true;
        }
        return false;
      }
    }
    return false;
  }

  const requestedToLead = () => {
    if (project.requestedToLead) {
      for (let i = 0; i < project.requestedToLead.length; i++) {
        if (project.requestedToLead[i].userId === store.user.userId)
          return true;
      }
      return false;
    }
  }

  const requestedToBeAssigned = () => {
    if (story) {
      if (story.requestedToBeAssigned) {
        for (let i = 0; i < story.requestedToBeAssigned.length; i++) {
          if (story.requestedToBeAssigned[i].userId === store.user.userId) {
            return true;
          }
        }
        return false;
      }
    }
    if (task) {
      if (task.requestedToBeAssigned) {
        for (let i = 0; i < task.requestedToBeAssigned.length; i++) {
          if (task.requestedToBeAssigned[i].userId === store.user.userId)
            return true;
        }
        return false;
      }
    }
  }

  const updateQuery = async () => {
    if (project) {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      if (archiveMode)
        queryClient.invalidateQueries({ queryKey: ["archivedProjects"] });
    }
    if (story) {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      if (archiveMode)
        queryClient.invalidateQueries({ queryKey: ["archivedStories"] });
    }
    if (task) {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      if (archiveMode)
        queryClient.invalidateQueries({ queryKey: ["archivedTasks"] });
    }
    queryClient.invalidateQueries({ queryKey: ["users"] });
  }

  const requestJoin = async () => {
    getState().setShowConfirmFormModal({
      text: "Describe your motivation for joining this project",
      onConfirm: (motivation) => {
        ApiClient.projects.requestToJoin(id, motivation).then(() => updateQuery())
      },
    });
    updateQuery();
  };

  const requestLead = () => {
    getState().setShowConfirmFormModal({
      text: "Describe your motivation for leading this project",
      onConfirm: (motivation) => {
        ApiClient.projects.requestToLead(id, motivation).then(() => updateQuery())
      },
    });
    updateQuery();
  };

  const requestAssign = async () => {
    if (story) {
      await ApiClient.stories.requestToAssign(id, store.user.userId).then(() => updateQuery())
    }
    if (task) {
      await ApiClient.tasks.requestToAssign(id, store.user.userId).then(() => updateQuery())
    }
  };

  const sendComment = async (text: string) => {
    if (text) {
      if (task) {
        await ApiClient.tasks.addCommentToTask(id, text)
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
      } else if (story) {
        await ApiClient.stories.addCommentToStory(id, text)
        queryClient.invalidateQueries({ queryKey: ["stories"] });
      } else if (project) {
        await ApiClient.projects.addCommentToProject(id, text)
        queryClient.invalidateQueries({ queryKey: ["projects"] });
      }
      setCommentInput('');
      setCommentInputFocus(false);
      updateQuery();
    }
  }

  const editComment = async (commentId: number, text: string) => {
    if (!archiveMode) {
      setEditField(text);
      setEditingComment(commentId);
    }
  }

  const updateComment = async (commentId: number, text: string) => {
    await ApiClient.comments.updateComment(commentId, text).then(() => {
      updateQuery();
      onSubmit;
      setEditingComment(0);
    });
  }

  const deleteComment = async (commentId: number) => {
    await ApiClient.comments.deleteComment(commentId).then(() => {
      updateQuery();
    });
    setOpenConfirmDialog(false);
  }

  const archived = () => {
    if (project?.archived || story?.archived || task?.archived)
      return true;
    return false;
  }

  const havePermission = () => {
    if (project) {
      if (
        project.owner.userId === store.user.userId ||
        project.teamLead?.userId === store.user.userId ||
        store.user.role === "Admin"
      )
        return true;
    }
    if (story) {
      if (
        story.createdBy.userId === store.user.userId ||
        story.assignedUser?.userId === store.user.userId ||
        story.project.owner.userId === store.user.userId ||
        story.project.teamLead?.userId === store.user.userId ||
        store.user.role === "Admin"
      )
        return true;
    }
    if (task) {
      if (
        task.createdBy.userId === store.user.userId ||
        task.assignedUser?.userId === store.user.userId ||
        task.story.createdBy.userId === store.user.userId ||
        task.story.assignedUser?.userId === store.user.userId ||
        task.story.project.owner.userId === store.user.userId ||
        task.story.project.teamLead?.userId === store.user.userId ||
        store.user.role === "Admin"
      )
        return true;
    }
    return false;
  }

  return (
    <Modal
      key={id}
      open={open}
      onClose={handleCloseModal}
      modalWidth={900}
      panels={panels}
    >
      <Box component="form" onSubmit={onSubmit}>
        <Stack direction="row" spacing={5}>
          <Box sx={{ flex: "1 1 70%", display: 'flex' }}>
            <Stack direction='column' sx={{ display: 'flex', width: '100%', gap: 4 }}>
              <Box>
                <Box sx={{
                  maxWidth: "500px",
                }}>
                  <EditableField
                    label={null}
                    fieldProps={{ name: "name" }}
                    fontSize={1.5}
                    content={formInput.title || formInput.name}
                    onInput={onInput}
                    onBlur={onSubmit}
                    onClearValue={onClearValue}
                    disabled={archived() ? true : false}
                  />
                </Box>
                <Attachments type={type} id={id} />
                <Box marginTop={2} sx={{ maxHeight: 200, maxWidth: 500, overflowY: 'auto' }}>
                  <EditableField
                    label="Description"
                    fieldProps={{ name: "description" }}
                    typographyProps={{
                      variant: "body2",
                    }}
                    fontSize={1}
                    content={formInput.description}
                    onInput={onInput}
                    onBlur={onSubmit}
                    onClearValue={onClearValue}
                    disabled={archived() ? true : false}
                  />
                </Box>
              </Box>
              <Stack gap={2}>
                <Stack gap={1}>
                  Comments
                  <TextField
                    name="addComment"
                    id="addComment"
                    placeholder="Add a comment..."
                    multiline
                    fullWidth
                    value={commentInput}
                    onFocus={() => setCommentInputFocus(true)}
                    onChange={(event) => setCommentInput(event.target.value)}
                    disabled={archived() ? true : false}
                    sx={{ maxHeight: 200, overflowY: 'auto' }}
                  />
                  <Stack direction='row' gap={1}>
                    <Button variant="contained" sx={{ display: `${!commentInputFocus && 'none'}` }}
                      onClick={() => sendComment(commentInput)}
                    >
                      add
                    </Button>
                    <Button onClick={() => { setCommentInputFocus(false); setCommentInput(''); }}
                      sx={{ color: 'white', paddingLeft: 1.4, paddingRight: 1.4, display: `${!commentInputFocus && 'none'}` }}
                    >
                      cancel
                    </Button>
                  </Stack>
                </Stack>
                {type === "projects" &&
                  <Stack gap={2}>
                    {project.comments?.map(comment =>
                      <Stack gap={2} direction='row' key={comment.commentId}>
                        <Avatar
                          key={comment.createdBy.userId}
                          name={comment.createdBy.name}
                          lastName={comment.createdBy.lastName}
                          sx={{
                            fontSize: "1.3em",
                          }}
                        />
                        <Stack gap={1} direction='column' width='100%'>
                          <Stack direction='row' gap={1}>
                            {comment.createdBy.username}
                            <Typography variant="subtitle2">
                              {dayjs(comment.created).toString()}
                            </Typography>
                          </Stack>
                          <Typography sx={{ display: `${editingComment === comment.commentId && 'none'}`, whiteSpace: "pre" }}>
                            {comment.text}
                          </Typography>
                          <Stack gap={1} sx={{ display: `${editingComment === comment.commentId ? 'flex' : 'none'}` }}>
                            <TextField
                              id={comment.commentId.toString()}
                              value={editField}
                              onChange={(event) => setEditField(event.target.value)}
                              multiline
                              fullWidth
                            />
                            <Stack direction='row' gap={1}>
                              <Button variant="contained"
                                onClick={() => updateComment(comment.commentId, editField)}
                              >
                                save
                              </Button>
                              <Button onClick={() => setEditingComment(0)} sx={{ color: 'white', paddingLeft: 1.4, paddingRight: 1.4 }}>
                                cancel
                              </Button>
                            </Stack>
                          </Stack>
                          {dayjs().diff(dayjs(comment.created), 'day') < 1 &&
                            <Box display='flex' gap={1}>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => editComment(comment.commentId, comment.text)}>
                                Edit
                              </Typography>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => { !archived() && setOpenConfirmDialog(true) }}>
                                Delete
                              </Typography>
                              <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} >
                                <DialogTitle id="alert-dialog-title">
                                  Delete comment?
                                </DialogTitle>
                                <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                  <Button onClick={handleCloseConfirmDialog} sx={{ width: '100%' }}>
                                    No
                                  </Button>
                                  <Button color="error" onClick={() => deleteComment(comment.commentId)} sx={{ width: '100%' }}>
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                          }
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                }
                {type === "stories" &&
                  <Stack gap={2}>
                    {story.comments?.map(comment =>
                      <Stack gap={2} direction='row' key={comment.commentId}>
                        <Avatar
                          key={comment.createdBy.userId}
                          name={comment.createdBy.name}
                          lastName={comment.createdBy.lastName}
                          sx={{
                            fontSize: "1.3em",
                          }}
                        />
                        <Stack gap={1} direction='column' width='100%'>
                          <Stack direction='row' gap={1}>
                            {comment.createdBy.username}
                            <Typography variant="subtitle2">
                              {dayjs(comment.created).toString()}
                            </Typography>
                          </Stack>
                          <Typography sx={{ display: `${editingComment === comment.commentId && 'none'}`, whiteSpace: "pre" }}>
                            {comment.text}
                          </Typography>
                          <Stack gap={1} sx={{ display: `${editingComment === comment.commentId ? 'flex' : 'none'}` }}>
                            <TextField
                              id={comment.commentId.toString()}
                              value={editField}
                              onChange={(event) => setEditField(event.target.value)}
                              multiline
                              fullWidth
                            />
                            <Stack direction='row' gap={1}>
                              <Button variant="contained"
                                onClick={() => updateComment(comment.commentId, editField)}
                              >
                                save
                              </Button>
                              <Button onClick={() => setEditingComment(0)} sx={{ color: 'white', paddingLeft: 1.4, paddingRight: 1.4 }}>
                                cancel
                              </Button>
                            </Stack>
                          </Stack>
                          {dayjs().diff(dayjs(comment.created), 'day') < 1 &&
                            <Box display='flex' gap={1}>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => editComment(comment.commentId, comment.text)}>
                                Edit
                              </Typography>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => setOpenConfirmDialog(true)}>
                                Delete
                              </Typography>
                              <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} >
                                <DialogTitle id="alert-dialog-title">
                                  Delete comment?
                                </DialogTitle>
                                <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                  <Button onClick={handleCloseConfirmDialog} sx={{ width: '100%' }}>
                                    No
                                  </Button>
                                  <Button color="error" onClick={() => deleteComment(comment.commentId)} sx={{ width: '100%' }}>
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                          }
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                }
                {type === "tasks" &&
                  <Stack gap={2}>
                    {task.comments?.map(comment =>
                      <Stack gap={2} direction='row' key={comment.commentId}>
                        <Avatar
                          key={comment.createdBy.userId}
                          name={comment.createdBy.name}
                          lastName={comment.createdBy.lastName}
                          sx={{
                            fontSize: "1.3em",
                          }}
                        />
                        <Stack gap={1} direction='column' width='100%'>
                          <Stack direction='row' gap={1}>
                            {comment.createdBy.username}
                            <Typography variant="subtitle2">
                              {dayjs(comment.created).toString()}
                            </Typography>
                          </Stack>
                          <Typography sx={{ display: `${editingComment === comment.commentId && 'none'}`, whiteSpace: "pre" }}>
                            {comment.text}
                          </Typography>
                          <Stack gap={1} sx={{ display: `${editingComment === comment.commentId ? 'flex' : 'none'}` }}>
                            <TextField
                              id={comment.commentId.toString()}
                              value={editField}
                              onChange={(event) => setEditField(event.target.value)}
                              multiline
                              fullWidth
                            />
                            <Stack direction='row' gap={1}>
                              <Button variant="contained"
                                onClick={() => updateComment(comment.commentId, editField)}
                              >
                                save
                              </Button>
                              <Button onClick={() => setEditingComment(0)} sx={{ color: 'white', paddingLeft: 1.4, paddingRight: 1.4 }}>
                                cancel
                              </Button>
                            </Stack>
                          </Stack>
                          {dayjs().diff(dayjs(comment.created), 'day') < 1 &&
                            <Box display='flex' gap={1}>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => editComment(comment.commentId, comment.text)}>
                                Edit
                              </Typography>
                              <Typography variant="body2" color='lightgray' sx={{ cursor: 'pointer' }} onClick={() => setOpenConfirmDialog(true)}>
                                Delete
                              </Typography>
                              <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} >
                                <DialogTitle id="alert-dialog-title">
                                  Delete comment?
                                </DialogTitle>
                                <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                  <Button onClick={handleCloseConfirmDialog} sx={{ width: '100%' }}>
                                    No
                                  </Button>
                                  <Button color="error" onClick={() => deleteComment(comment.commentId)} sx={{ width: '100%' }}>
                                    Yes
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Box>
                          }
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                }
              </Stack>
            </Stack>
          </Box>
          <Stack sx={{ display: 'flex', direction: 'column' }} gap={1}>
            {type === "projects" &&
              <Stack direction='row' gap={1} justifyContent="space-between">
                {(includeUser() || project.archived) ? <></> :
                  <>
                    {requestedToJoin() ?
                      <Button variant="contained" disabled>
                        asked to join
                      </Button>
                      : <Button variant="contained" onClick={requestJoin}>
                        ask to join
                      </Button>
                    }
                  </>
                }
                {(project.teamLead || project.archived) ? <></> :
                  <>
                    {requestedToLead() ?
                      <Button variant="contained" disabled>
                        asked to lead
                      </Button>
                      : <Button variant="contained" color="secondary" onClick={requestLead}>
                        ask to lead
                      </Button>
                    }
                  </>
                }
              </Stack>
            }
            {type === "stories" &&
              <> {(story.assignedUser || story.archived) ? <></> :
                <Stack direction='row' gap={1}>
                  {requestedToBeAssigned() ?
                    <Button variant="contained" disabled>
                      requested to be assigned
                    </Button>
                    : <Button variant="contained" onClick={requestAssign}>
                      request to be assigned
                    </Button>
                  }
                </Stack>
              }
              </>
            }
            {type === "tasks" &&
              <> {(task.assignedUser || task.archived) ? <></> :
                <Stack direction='row' gap={1}>
                  {requestedToBeAssigned() ?
                    <Button variant="contained" disabled>
                      requested to be assigned
                    </Button>
                    : <Button variant="contained" onClick={requestAssign}>
                      request to be assigned
                    </Button>
                  }
                </Stack>
              }
              </>
            }
            <Accordion
              defaultExpanded
              variant="outlined"
              sx={{ backgroundColor: grey[900] }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  height: "40px",
                  minHeight: "40px",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  "&.Mui-expanded": {
                    height: "50px",
                    minHeight: "50px",
                  },
                }}
              >
                <Typography>Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Stack spacing={1}>
                    {type === "projects" ?
                      <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">Followers:</Typography>
                          <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end' }} gap={1}>
                            {havePermission() ?
                              <>
                                <Autocomplete
                                  sx={{ width: 160 }}
                                  options={users || []}
                                  multiple
                                  disableClearable
                                  size='small'
                                  value={project.followers}
                                  onChange={(event: SyntheticEvent, newValue: ApiUser[] | null) => {
                                    if ((project?.followers?.length || 0) >= (newValue?.length || 0))
                                      unassign("Follower", project?.followers?.find(user => !newValue?.includes(user))?.userId);
                                    else
                                      assignUser("Follower", newValue?.find(user => !project?.followers?.includes(user))?.userId || null);
                                  }}
                                  isOptionEqualToValue={(option, value) => {
                                    if (option.userId === value.userId)
                                      return true
                                    else
                                      return false
                                  }}
                                  inputValue={followersSelectInputValue}
                                  onInputChange={(event, newValue) => {
                                    setFollowersSelectInputValue(newValue);
                                  }}
                                  limitTags={3}
                                  getOptionLabel={(option) => option.username}
                                  renderTags={(value: ApiUser[]) =>
                                    value.map((option: ApiUser, index: number) => (
                                      <Avatar name={option.name} lastName={option.lastName}
                                        sx={{
                                          width: "32px",
                                          height: "32px",
                                          fontSize: "1em",
                                          marginRight: 0.5,
                                          display: (index > 2 ? "none" : "visible"),
                                        }}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                      />
                                    );
                                  }}
                                />
                              </> : <>
                                {project.followers ?
                                  <>
                                    {
                                      project.followers.map(follower =>
                                        <Avatar key={follower.userId} name={follower.name} lastName={follower.lastName}
                                          sx={{
                                            width: "32px",
                                            height: "32px",
                                            fontSize: "1em",
                                          }}
                                        />
                                      )
                                    }
                                  </>
                                  :
                                  <Typography variant="body2">
                                    No followers
                                  </Typography>
                                }
                              </>
                            }
                          </Stack>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">Project Lead:</Typography>
                          {havePermission() ?
                            <>
                              <Autocomplete
                                sx={{ width: 160 }}
                                options={users || []}
                                size='small'
                                value={project?.teamLead}
                                onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
                                  if (newValue)
                                    assignUser("TeamLead", newValue?.userId)
                                  else
                                    unassign("TeamLead")
                                }}
                                inputValue={teamLeadInputValue}
                                onInputChange={(event, newValue) => {
                                  setTeamLeadInputValue(newValue);
                                }}
                                getOptionLabel={(option) => option.username}
                                renderInput={(params) => {
                                  return (
                                    <TextField {...params} />
                                  );
                                }}
                              />
                            </> : <>
                              {project.teamLead ?
                                <Stack direction='row' alignItems='center' spacing={1}>
                                  <Avatar key={project.teamLead.userId} name={project.teamLead.name} lastName={project.teamLead.lastName}
                                    sx={{
                                      width: "32px",
                                      height: "32px",
                                      fontSize: "1em",
                                    }}
                                  />
                                  <Typography>
                                    {project.teamLead.username}
                                  </Typography>
                                </Stack>
                                :
                                <Typography variant="body2">
                                  Unassigned
                                </Typography>
                              }
                            </>
                          }
                        </Stack>
                      </Stack>
                      : <></>
                    }
                    {type === "stories" &&
                      <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">Assigned:</Typography>
                          {havePermission() ?
                            <>
                              <Autocomplete
                                sx={{ width: 160 }}
                                options={users || []}
                                size='small'
                                value={story?.assignedUser}
                                onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
                                  if (newValue)
                                    assignUser("AssignedUser", newValue?.userId)
                                  else
                                    unassign("AssignedUser")
                                }}
                                inputValue={teamLeadInputValue}
                                onInputChange={(event, newValue) => {
                                  setTeamLeadInputValue(newValue);
                                }}
                                getOptionLabel={(option) => option.username}
                                renderInput={(params) => {
                                  return (
                                    <TextField {...params} />
                                  );
                                }}
                              />
                            </> : <>
                              {story.assignedUser ?
                                <Stack direction='row' alignItems='center' spacing={1}>
                                  <Avatar key={story.assignedUser.userId} name={story.assignedUser.name} lastName={story.assignedUser.lastName}
                                    sx={{
                                      width: "32px",
                                      height: "32px",
                                      fontSize: "1em",
                                    }}
                                  />
                                  <Typography>
                                    {story.assignedUser.username}
                                  </Typography>
                                </Stack>
                                :
                                <Typography variant="body2">
                                  Unassigned
                                </Typography>
                              }
                            </>
                          }
                        </Stack>
                      </Stack>
                    }
                    {type === "tasks" &&
                      <Stack gap={1}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">Assigned:</Typography>
                          {havePermission() ?
                            <>
                              <Autocomplete
                                sx={{ width: 160 }}
                                options={users || []}
                                size='small'
                                value={task?.assignedUser}
                                onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
                                  if (newValue)
                                    assignUser("AssignedUser", newValue?.userId)
                                  else
                                    unassign("AssignedUser")
                                }}
                                inputValue={teamLeadInputValue}
                                onInputChange={(event, newValue) => {
                                  setTeamLeadInputValue(newValue);
                                }}
                                getOptionLabel={(option) => option.username}
                                renderInput={(params) => {
                                  return (
                                    <TextField {...params} />
                                  );
                                }}
                              />
                            </> : <>
                              {task.assignedUser ?
                                <Stack direction='row' alignItems='center' spacing={1}>
                                  <Avatar key={task.assignedUser.userId} name={task.assignedUser.name} lastName={task.assignedUser.lastName}
                                    sx={{
                                      width: "32px",
                                      height: "32px",
                                      fontSize: "1em",
                                    }}
                                  />
                                  <Typography>
                                    {task.assignedUser.username}
                                  </Typography>
                                </Stack>
                                :
                                <Typography variant="body2">
                                  Unassigned
                                </Typography>
                              }
                            </>
                          }
                        </Stack>
                      </Stack>
                    }
                    <Stack direction="row" justifyContent="space-between" alignItems='center' paddingTop='4px'>
                      <Typography variant="subtitle2">Due date:</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          value={dayjs(formInput.dueDate)}
                          minDate={dayjs()}
                          onChange={onChangeDate}
                          sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '.MuiInputBase-input': {
                              width: 'min-content',
                              padding: '2px',
                            },
                            width: '56%',
                          }}
                          disabled={archived() ? true : false}
                        />
                      </LocalizationProvider>
                    </Stack>
                    {modalType === "story" && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2">
                          Potential reward:
                        </Typography>
                        <Typography variant="body2">
                          {story.potentialReward ? Math.round(story.potentialReward * 100) / 100 : 'Not set'}
                        </Typography>
                      </Stack>
                    )}
                    {modalType === "task" && (
                      <Stack direction="column" spacing={1}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">
                            Estimated hours:
                          </Typography>
                          <Typography variant="body2">
                            {task.estimatedHours || 'Not set'}
                          </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle2">
                            Potential reward:
                          </Typography>
                          <Typography variant="body2">
                            {task.potentialReward ? Math.round(task.potentialReward * 100) / 100 : 'Not set'}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1} sx={{ marginTop: 1 }}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle2">Created by:</Typography>
                      <Typography variant="body2">{createdBy?.name}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle2">Created at:</Typography>
                      <Typography variant="body2">
                        {dayjs(created).format("YYYY-MM-DD")}
                      </Typography>
                    </Stack>
                    {formInput.priority &&
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2">
                          Priority:
                        </Typography>
                        <PriorityChip
                          priority={formInput.priority as ApiStory["priority"]}
                          sx={{ alignSelf: "flex-end" }}
                        />
                      </Stack>
                    }
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Stack>
      </Box >
    </Modal >
  );
};

export default ModalView;
