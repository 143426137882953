import { Box, Button, CircularProgress, Container, Paper, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import ApiClient from "../api/axios.config";
import { useQuery } from "@tanstack/react-query";
import { setState } from "../store";
import { useLocation } from "wouter";


const Unavailable = () => {

  const [location, setLocation] = useLocation();

  const handleLogout = () => {
    setState({ token: null });
    setLocation("/login");
  };

  const { data: schedule, status: statusSchedule } = useQuery({
    queryKey: ["schedule"],
    queryFn: () => ApiClient.schedule.getSchedule().then((res) => res.data)
  });

  if (statusSchedule === 'loading') {
    return <CircularProgress />
  }

  return (
    <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
      <Paper sx={{ padding: 4, gap: 4, display: 'flex', flexDirection: 'column', maxWidth: 700 }}>
        <Typography variant="h6">
          Website is currently unavailable due to the schedule set by Admin.
        </Typography>
        <Typography fontWeight={700}>
          Current schedule is:
        </Typography>
        <Stack direction='column' spacing={3}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: 300
          }}
        >
          {schedule?.map((day) =>
            <Stack key={day.day} direction='row' spacing={2} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2">
                {day.day}
              </Typography>
              <Stack direction='row' gap={2}>
                <Typography>
                  {dayjs(schedule.find(item => item.day === day.day)?.start, "HH:mm").format("hh:mm a")}
                </Typography>
                -
                <Typography>
                  {dayjs(schedule.find(item => item.day === day.day)?.end, "HH:mm").format("hh:mm a")}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Button variant='contained' sx={{ width: 100 }} onClick={handleLogout}>Logout</Button>
      </Paper>
    </Container >
  );
};

export default Unavailable;
