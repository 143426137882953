import { useEffect, useState } from "react";
import ProjectsItem from "./components/ProjectsItem";
import NewProject from "./components/NewProject";
import { Grid, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import InnerHeader from "../../elements/InnerHeader";
import ApiClient from "../../api/axios.config";
import ViewProjects from "./components/ViewProjects";
import { ApiProject } from "../../api/schema";
import { useStore } from "../../store";

const Projects = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalViewProject, setShowModalViewProject] = useState<{
    show: boolean;
    project: ApiProject | null;
  }>({ show: false, project: null });
  const { data: projects, status } = useQuery({
    queryKey: ["projects"],
    queryFn: () => ApiClient.projects.listAllProjects().then((res) => res.data),
  });
  const { data: archivedProjects } = useQuery({
    queryKey: ["archivedProjects"],
    queryFn: () => ApiClient.projects.listAllProjects(true).then((res) => res.data),
  });
  const archiveMode = useStore((state) => state.archiveMode)

  useEffect(() => {
    const updatedProject = projects?.find(project => project.projectId === showModalViewProject.project?.projectId) || null;
    const isShowed = showModalViewProject.show
    setShowModalViewProject({ show: isShowed, project: updatedProject});
  }, [projects, archiveMode]);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalViewProject({ show: false, project: null });
  };

  return (
    <>
      <InnerHeader title="Projects" onNewProject={() => setShowModal(true)} />
      <NewProject open={showModal} onClose={handleCloseModal} />
      <Grid container spacing={2} justifyContent="flex-start" direction="row">
        {status === "loading" &&
          [...Array(12)
            .keys()]
            .map((i) => (
              <Grid item xs={5} sm={4} xl={3} key={i}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  variant="rectangular"
                  height={310}
                />
              </Grid>
            ))}
        {(archiveMode ? archivedProjects : projects)?.map((project) => (
          <Grid item key={project.projectId}>
            <ProjectsItem
              name={project.name}
              projectId={project.projectId}
              dueDate={project.dueDate}
              description={project.description}
              storiesCount={project.stories?.length - 1}
              budget={project.budget}
              teamLead={project.teamLead}
              archived={project.archived}
              onClick={() => setShowModalViewProject({ show: true, project })}
            />
          </Grid>
        ))}
      </Grid>
      {showModalViewProject.project && (
        <ViewProjects
          project={showModalViewProject.project}
          open={showModalViewProject.show}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Projects;

