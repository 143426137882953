import React, { useState } from "react";
import InnerHeader from "../../elements/InnerHeader";
import { Grid, Skeleton } from "@mui/material";
import { ApiUser } from "../../api/schema";
import { useQuery } from "@tanstack/react-query";
import ApiClient from "../../api/axios.config";
import UserItem from "./components/UserItem";
import NewUser from "./components/NewUser";
import ViewUser from "./components/ViewUser";

const Users = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalViewUser, setShowModalViewUser] = useState<{
    show: boolean;
    user: ApiUser | null;
  }>({ show: false, user: null });

  const { data: users, status } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalViewUser({ show: false, user: null });
  };

  return (
    <>
      <InnerHeader title="Users" onNewProject={() => setShowModal(true)} />
      <NewUser open={showModal} onClose={handleCloseModal} />
      <Grid container spacing={2}>
        {status === "loading" &&
          Array(12)
            .fill(0)
            .map(() => (
              <Grid item xs={5} sm={4} xl={3}>
                <Skeleton
                  sx={{ bgcolor: "grey.900" }}
                  variant="rectangular"
                  height={310}
                />
              </Grid>
            ))}
        {users?.map((user) => (
          <Grid item key={user.userId} xs={5} sm={4} xl={3}>
            <UserItem
              name={user.name}
              lastName={user.lastName}
              role={user.role}
              userId={user.userId}
              email={user.email}
              onClick={() => setShowModalViewUser({ show: true, user })}
            />
          </Grid>
        ))}
      </Grid>
      {showModalViewUser.user && (
        <ViewUser
          open={showModalViewUser.show}
          user={showModalViewUser.user}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Users;
