import React, { SyntheticEvent, useReducer, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import Modal from "../../../elements/Modal/Modal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { ApiStory, ApiTaskDto, ApiUser } from "../../../api/schema";
import { formatNumber } from '../../../helpers/helpers';

const priorities = [
  { value: "High", label: "High" },
  { value: "Medium", label: "Medium" },
  { value: "Low", label: "Low" },
  { value: "Critical", label: "Critical" },
];

const NewTask = ({
  stories,
  open,
  onClose,
}: {
  stories?: ApiStory[];
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });
  const { mutate } = useMutation((payload: ApiTaskDto) =>
    ApiClient.tasks.createTask(payload)
  );

  const { mutate: mutateTaskToStory } = useMutation(
    ({ storyId, taskId }: { storyId: number; taskId: number }) =>
      ApiClient.stories.addTaskToStory(storyId, taskId)
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiTaskDto, newState: Partial<ApiTaskDto>) => ({
      ...state,
      ...newState,
    }),
    {
      title: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      assignedUserUsername: "",
      type: "Regular",
      estimatedHours: 0,
      priority: "Medium",
      storyId: 0,
    }
  );

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();
    mutate(formInput, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        onClose();
      },
    });
  };

  const [selectedAssignedUser, setSelectedAssignedUser] = useState<ApiUser | null>(null);
  const [assignedUserInputValue, setAssignedUserInputValue] = useState("");

  return (
    <Modal open={open} onClose={onClose} modalWidth={400}>
      <Box component="form" onSubmit={handleSubmitForm}>
        <Typography variant="h5" align="center" gutterBottom>
          Create new task
        </Typography>

        <TextField
          label="Task title"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          inputProps={{ maxLength: 100 }}
          name="title"
          onInput={handleInput}
        />
        <TextField
          label="Description"
          variant="outlined"
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
          name="description"
          onInput={handleInput}
        />
        <TextField
          select
          label="Parent story"
          name="story"
          value={formInput.storyId}
          onChange={(event) =>
            setFormInput({ storyId: Number(event.target.value) })
          }
          fullWidth
          margin="normal"
        >
          {stories?.map((story) => (
            <MenuItem key={story.storyId} value={story.storyId}>
              {story.title}
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ marginTop: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Due date"
              value={dayjs(formInput.dueDate)}
              minDate={dayjs()}
              onChange={(date) =>
                setFormInput({ dueDate: date?.format("YYYY-MM-DD") })
              }
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <TextField
          inputProps={{ inputMode: 'numeric', pattern: '^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$' }}
          label="Estimated hours"
          name="estimatedHours"
          value={formInput.estimatedHours}
          onChange={handleInput}
          onWheel={ event => event.currentTarget.blur() }
          fullWidth
          margin="normal"
        />
        <TextField
          select
          label="Priority"
          name="priority"
          defaultValue={priorities[1].value || "none"}
          value={formInput.priority}
          onChange={handleInput}
          fullWidth
          margin="normal"
        >
          {priorities.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          sx={{ marginTop: 1 }}
          options={users || []}

          value={selectedAssignedUser}
          onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
            setSelectedAssignedUser(newValue);
            setFormInput({ ["assignedUserUsername"]: newValue?.username });
          }}

          inputValue={assignedUserInputValue}
          onInputChange={(event, newValue) => {
            setAssignedUserInputValue(newValue);
          }}
          fullWidth
          getOptionLabel={(option) => option.username}
          renderInput={(params) => {
            return <TextField label='Assigned User' {...params} />;
          }}
        />

        <Stack
          direction="column"
          justifyContent="space-around"
          mt={2}
          spacing={2}
        >
          <Button size="large" type="submit">
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default NewTask;
