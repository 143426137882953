import React, { useEffect, useState } from "react";
import ApiClient from "../../api/axios.config";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InnerHeader from "../../elements/InnerHeader";
import { ApiStory, ApiUserDto } from "../../api/schema";
import ViewStory from "./components/ViewStory";
import NewStory from "./components/NewStory";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { css } from "@emotion/react";
import { green, grey } from "@mui/material/colors";
import { Params, useLocation } from "wouter";
import Filters from "./components/Filters";
import PriorityChip from "../../elements/PriorityChip";
import { useStore } from "../../store";

const tableRowStyle = css`
  position: relative;

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${grey[900]};
  }

  &:hover button {
    opacity: 1;
  }
`;

const Stories = ({ params }: { params: Params }) => {
  const queryClient = useQueryClient();
  const [location, setLocation] = useLocation();
  const archiveMode = useStore((state) => state.archiveMode);

  const [showModalNewStory, setShowModalNewStory] = useState(false);
  const [showModalViewStory, setShowModalViewStory] = useState<{
    show: boolean;
    story: ApiStory | null;
  }>({ show: false, story: null });
  const [stories, setStories] = useState<ApiStory[] | null>(null);
  const [archivedStories, setArchivedStories] = useState<ApiStory[] | null>(null);
  const [openToAll, setOpenToAll] = useState(false);

  const { mutate } = useMutation((storyId: number) =>
    ApiClient.stories.deleteStory(storyId)
  );

  const { refetch } = useQuery({
    queryKey: ["stories"],
    queryFn: () =>
      ApiClient.stories
        .listAllStories(
          Number(params.projectId)
            ? { projectId: Number(params.projectId) }
            : {}
        )
        .then((res) => res.data),
    onSuccess: (stories) => {
      if (params.projectId) {
        setStories(
          stories.filter((story) =>
            story.project.projectId === Number(params.projectId)
          )
        );
      } else {
        setStories(stories);
      }
    },
  });

  useQuery({
    queryKey: ["archivedStories"],
    queryFn: () =>
      ApiClient.stories
        .listAllStories(
          Number(params.projectId)
            ? { projectId: Number(params.projectId), archived: true }
            : { archived: true }
        )
        .then((res) => res.data),
    onSuccess: (archivedStories) => {
      if (params.projectId) {
        setArchivedStories(
          archivedStories.filter((story) =>
            story.project.projectId === Number(params.projectId)
          )
        );
      } else {
        setArchivedStories(archivedStories);
      }
    },
  });

  const { data: projects } = useQuery({
    queryKey: ["projects"],
    queryFn: () => ApiClient.projects.listAllProjects().then((res) => res.data),
  });
  const { data: archivedProjects } = useQuery({
    queryKey: ["archivedProjects"],
    queryFn: () => ApiClient.projects.listAllProjects(true).then((res) => res.data),
  });

  useEffect(() => {
    const updatedStory = (archiveMode ? archivedStories : stories)?.find(story => story.storyId === showModalViewStory.story?.storyId) || null;
    const isShowed = showModalViewStory.show
    setShowModalViewStory({ show: isShowed, story: updatedStory });
  }, [params.projectId, projects, archiveMode, stories]);

  const handleCloseModal = () => {
    setShowModalNewStory(false);
    setShowModalViewStory({ show: false, story: null });
  };

  const handleOpenToAllChange = (toAll: boolean) => {
    setOpenToAll(toAll);
    refetch();
  };

  const handleDeleteStory = (storyId: number) => {
    mutate(storyId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["stories"] });
      },
    });
  };

  return (
    <>
      <InnerHeader
        title="Stories"
        onNewProject={() => setShowModalNewStory(true)}
        withoutMargin
      />
      <NewStory
        projects={archiveMode ? archivedProjects : projects}
        open={showModalNewStory}
        onClose={handleCloseModal}
      />
      <Filters projects={archiveMode ? archivedProjects : projects} onOpenToAllChange={handleOpenToAllChange} />
      <Grid container>
        <TableContainer component={Paper} sx={{ overflow: "visible" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="left">Tasks completed</TableCell>
                <TableCell align="left">Priority</TableCell>
                <TableCell align="center">Assigned user</TableCell>
                <TableCell align="center">Due date</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="left">Parent project</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Potential reward</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(archiveMode ? archivedStories : stories)?.map((story) => (
                <TableRow
                  key={story.storyId}
                  css={tableRowStyle}
                  onClick={() => setShowModalViewStory({ show: true, story })}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    size="small"
                    sx={{ maxWidth: "300px" }}
                  >
                    {story.title}
                  </TableCell>
                  <TableCell align="center" size="small">
                    {`${story.tasks.reduce(
                      (acc, curr) => (curr.status === "DONE" ? acc + 1 : acc),
                      0
                    )}/${story.tasks.length}`}
                  </TableCell>
                  <TableCell align="center" size="small">
                    {story.priority && (
                      <PriorityChip priority={story.priority} />
                    )}
                  </TableCell>
                  <TableCell align="center" size="small">
                    {story.assignedUser ? story.assignedUser.username : 'unassigned'}
                  </TableCell>
                  <TableCell align="center" size="small">
                    {dayjs(story.dueDate).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center" size="small">
                    <Button
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setLocation(`tasks/${story.storyId}`);
                      }}
                    >
                      Tasks
                    </Button>
                  </TableCell>
                  <TableCell align="left" size="small">
                    {story.project.name}
                  </TableCell>
                  <TableCell
                    align="right"
                    size="small"
                    sx={{ maxWidth: "300px" }}
                  >
                    {story.description.slice(0, 100)}
                  </TableCell>
                  <TableCell
                    align="right"
                    size="small"
                    sx={{ maxWidth: "300px" }}
                  >
                    {story.potentialReward ? Math.round(story.potentialReward * 100) / 100 : 'Not set'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {showModalViewStory.story && (
        <ViewStory
          story={showModalViewStory.story}
          open={showModalViewStory.show}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Stories;
