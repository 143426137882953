import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton
} from "@mui/material";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { getState, useStore, setState } from '../../store';
import { useLocation } from "wouter";
import Logo from '/kevin-logo.svg';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const menuItems = [
  { name: "dashboard", icon: <DashboardIcon fontSize="small" /> },
  { name: "rewards", icon: <GridViewOutlinedIcon fontSize="small" /> },
  { name: "projects", icon: <AccountTreeOutlinedIcon fontSize="small" /> },
  { name: "stories", icon: <AutoStoriesOutlinedIcon fontSize="small" /> },
  { name: "tasks", icon: <TaskOutlinedIcon fontSize="small" /> },
  { name: "users", icon: <PeopleAltOutlinedIcon fontSize="small" /> },
];

const Sidebar = () => {
  const { open } = useStore((state) => state.sidebar);
  const [location, setLocation] = useLocation();
  const showSidebar = useStore((state) => state.showSidebar);

  const user = useStore((state) => state.user);

  const handleSidebar = () => {
    setState({ showSidebar: !showSidebar });
  };

  return (
    <Drawer
      variant="persistent"
      open={open}
      sx={{
        width: getState().sidebar.width,
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        "& .MuiDrawer-paper": {
          width: getState().sidebar.width,
          boxSizing: "border-box",
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 3,
          paddingRight: 1
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }} onClick={() => setLocation('')}>
        <img src={Logo} width={getState().sidebar.width - 100} align-self='center' />
      </Box>
      <Stack
        component={List}
        dense
        disablePadding
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          direction: 'column',
          mt: 4
        }}
      >
        <Stack>
          {menuItems.map(({ name, icon }, index) => (
            <ListItem disableGutters key={index}>
              <ListItemButton
                sx={{ borderRadius: 1 }}
                selected={location === name}
                onClick={() => setLocation(name)}
              >
                <ListItemIcon sx={{ minWidth: "32px" }}>{icon}</ListItemIcon>
                <ListItemText primary={name[0].toUpperCase() + name.slice(1)} />
              </ListItemButton>
            </ListItem>
          ))}
        </Stack>
        <Stack gap={2}>
          <ListItemButton
            sx={{ borderRadius: 1, display: `${user.role !== "Admin" && 'none'}` }}
            selected={location === 'admin-panel'}
            onClick={() => setLocation('admin-panel')}
          >
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <AdminPanelSettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Admin panel' />
          </ListItemButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default Sidebar;
