import React, { SyntheticEvent, useEffect, useReducer, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import Modal from "../../../elements/Modal/Modal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { ApiProject, ApiStoryDto, ApiUser } from "../../../api/schema";

const priorities = [
  { value: "High", label: "High" },
  { value: "Medium", label: "Medium" },
  { value: "Low", label: "Low" },
  { value: "Critical", label: "Critical" },
];

const NewStory = ({
  projects,
  open,
  onClose,
}: {
  projects?: ApiProject[];
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });

  const { mutate: mutateStory } = useMutation((payload: ApiStoryDto) =>
    ApiClient.stories.createStory(payload).then((res) => res.data)
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiStoryDto, newState: Partial<ApiStoryDto>) => ({
      ...state,
      ...newState,
    }),
    {
      title: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      type: "Regular",
      priority: "Medium",
      projectId: 0,
    }
  );

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;

    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = async (evt: SyntheticEvent) => {
    evt.preventDefault();

    mutateStory(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["stories"] });
        onClose();
      },
    });
  };

  const [selectedAssignedUser, setSelectedAssignedUser] = useState<ApiUser | null>(null);
  const [assignedUserInputValue, setAssignedUserInputValue] = useState("");

  return (
    <Modal open={open} onClose={onClose} modalWidth={400}>
      <Box component="form" onSubmit={handleSubmitForm}>
        <Typography variant="h5" align="center" gutterBottom>
          Create new story
        </Typography>

        <TextField
          label="Story title"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          inputProps={{ maxLength: 100 }}
          name="title"
          onInput={handleInput}
        />
        <TextField
          label="Description"
          variant="outlined"
          required
          fullWidth
          multiline
          rows={4}
          margin="normal"
          name="description"
          onInput={handleInput}
        />
        <TextField
          select
          label="Parent project"
          name="project"
          value={formInput.projectId}
          onChange={(event) =>
            setFormInput({ projectId: Number(event.target.value) })
          }
          fullWidth
          margin="normal"
        >
          {projects?.map((project) => (
            <MenuItem key={project.projectId} value={project.projectId}>
              {project.name}
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ marginTop: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Due date"
              value={dayjs(formInput.dueDate)}
              minDate={dayjs()}
              onChange={(date) =>
                setFormInput({ dueDate: date?.format("YYYY-MM-DD") })
              }
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Box>
        <TextField
          select
          label="Priority"
          name="priority"
          defaultValue={priorities[1].value || "none"}
          value={formInput.priority}
          onChange={handleInput}
          fullWidth
          margin="normal"
        >
          {priorities.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          sx={{ marginTop: 1 }}
          options={users || []}

          value={selectedAssignedUser}
          onChange={(event: SyntheticEvent, newValue: ApiUser | null) => {
            setSelectedAssignedUser(newValue);
            setFormInput({ ["assignedUserUsername"]: newValue?.username });
          }}

          inputValue={assignedUserInputValue}
          onInputChange={(event, newValue) => {
            setAssignedUserInputValue(newValue);
          }}
          fullWidth
          getOptionLabel={(option) => option.username}
          renderInput={(params) => {
            return <TextField label='Assigned User' {...params} />;
          }}
        />
        <Stack
          direction="column"
          justifyContent="space-around"
          mt={2}
          spacing={2}
        >
          <Button size="large" type="submit">
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default NewStory;
