import React, { useState } from "react";
import { FormatterProps, SelectCellFormatter } from "react-data-grid";
import { Row } from "../pages/Finance/Finance";

const SelectFormatter = ({
  row,
  onRowChange,
  isCellSelected,
  columnKey,
}: FormatterProps<Row, unknown> & { columnKey: string }) => {
  return (
    <SelectCellFormatter
      value={Boolean(row[columnKey])}
      disabled={columnKey === "taskUnforeseen"}
      onChange={() => {
        // @ts-ignore
        onRowChange({
          ...row,
          [columnKey]: !row[columnKey],
        });
      }}
      isCellSelected={isCellSelected}
    />
  );
};

export default SelectFormatter;
