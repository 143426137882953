import { StoreSlice } from "./store";
import { AxiosError } from "axios";
import {
  ApiUser,
} from "./api/schema";
import { VariantType } from "notistack";

export interface MainSettings {
  showSidebar: boolean;
  token: string | null;
  notifications: { message: string; variant: VariantType }[];
  setNotification: (message: string, variant: VariantType) => void;
  removeNotifications: () => void;
  errors: AxiosError<{ errors?: [] }>[];
  sidebar: {
    open: boolean;
    width: number;
    height: number;
  };
  openSidebar: () => void;
  setError: (error: AxiosError<{ errors?: [] }>) => void;
  removeError: () => void;
  confirmationModal: {
    text: string | null;
    onConfirm: (() => void) | null;
  };
  setShowConfirmationModal: ({
    text,
    onConfirm,
  }: {
    text: string;
    onConfirm: () => void;
  }) => void;
  confirmFormModal: {
    text: string | null;
    onConfirm: ((message: string) => void) | null;
  };
  setShowConfirmFormModal: ({
    text,
    onConfirm,
  }: {
    text: string;
    onConfirm: (message: string) => void;
  }) => void;
  removeConfirmationModal: () => void;
  removeConfirmFormModal: () => void;
}

export interface User {
  user: ApiUser;
}

export interface ArchiveMode {
  archiveMode: boolean;
}

export const createMainSettings: StoreSlice<MainSettings> = (set) => ({
  showSidebar: true,
  notifications: [],
  errors: [],
  token: null,
  sidebar: {
    open: true,
    width: 256,
    height: 64,
  },
  confirmationModal: { text: null, onConfirm: null },
  confirmFormModal: { text: null, onConfirm: null },
  setShowConfirmationModal: ({ text, onConfirm }) =>
    set(() => ({ confirmationModal: { text, onConfirm } })),
  setShowConfirmFormModal: ({ text, onConfirm }) =>
    set(() => ({ confirmFormModal: { text, onConfirm } })),
  removeConfirmationModal: () =>
    set(() => ({ confirmationModal: { text: null, onConfirm: null } })),
  removeConfirmFormModal: () =>
    set(() => ({ confirmFormModal: { text: null, onConfirm: null } })),
  removeNotifications: () =>
    set(({ notifications }) => ({ notifications: notifications.slice(1) })),
  setNotification: (message, variant) =>
    set(({ notifications }) => ({
      notifications: [...notifications, { message, variant }],
    })),
  setError: (error) => set(({ errors }) => ({ errors: [...errors, error] })),
  removeError: () => set(({ errors }) => ({ errors: errors.slice(1) })),
  openSidebar: () =>
    set(({ sidebar }) => ({
      sidebar: {
        ...sidebar,
        open: !sidebar.open,
      },
    })),
});

export const createUser: StoreSlice<User> = () => ({
  user: {
    version: 1,
    userId: 1,
    name: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    role: "Developer",
    active: true,
    employmentStatus: "FullTime",
    created: "",
    updated: "",
  },
});

export const createArchiveMode: StoreSlice<ArchiveMode> = () => ({
  archiveMode: false,
});