import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import { ApiTask } from "../../../api/schema";
import { Status } from "../Tasks";

import { css } from "@emotion/react";
import PriorityChip from "../../../elements/PriorityChip";
import { green, grey } from "@mui/material/colors";
import DotsMenu from "../../../elements/DotsMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import {getState} from "../../../store";

const cardStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 20px;
  overflow: visible;
  &:hover .MuiIconButton-root {
    opacity: 1;
  }
`;

const Task = ({
  task,
  index,
  onDragStart,
  onDragEnd,
  onDragEnter,
  onClick,
}: {
  task: ApiTask;
  index: number;
  onDragStart: (
    event: any,
    taskId: number,
    column: Status,
    position: number
  ) => void;
  onDragEnd: (event: any, column: Status, position: number) => void;
  onDragEnter: (event: any, position: number) => void;
  onClick: (event: any, task: ApiTask) => void;
}) => {
  const {
    taskId,
    title,
    dueDate,
    assignedUser,
    type,
    description,
    priority,
    estimatedHours
  } = task;
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => ApiClient.tasks.deleteTask(taskId), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
    },
  });

  return (
    <Card
      draggable
      elevation={5}
      onDragStart={(event) =>
        onDragStart(event, task.taskId, task.status, index)
      }
      onDragEnd={(event) => onDragEnd(event, task.status, index)}
      onDragEnter={(event) => onDragEnter(event, index)}
      css={cardStyle}
    >
      <CardActionArea onClick={(event) => onClick(event, task)}>
        <CardContent>
          <Typography
            variant="h6"
            component="h3"
            overflow="hidden"
            sx={{ maxHeight: 64 }}
          >
            {title || "blank"}
          </Typography>
          {description && (
            <Typography
              variant="body2"
              component="h3"
              overflow="hidden"
              sx={{ whiteSpace: "break-spaces" }}
            >
              {description}
            </Typography>
          )}
          <Stack direction="row" justifyContent="space-between">
            {dueDate && (
              <Typography variant="body2" color="text.secondary" mt={1}>
                Due date: {dueDate}
              </Typography>
            )}
            {assignedUser && (
              <Typography variant="body2" color="text.secondary" mt={1}>
                {assignedUser.name || assignedUser.username}
              </Typography>
            )}
          </Stack>
        </CardContent>
        <DotsMenu
          buttonSx={{
            opacity: 0,
            position: "absolute",
            top: "-14px",
            right: "-14px",
            border: `1px solid ${grey[800]}`,
            svg: {
              fontSize: "1rem",
            },
            '&[aria-expanded="true"]': {
              opacity: 1,
            },
          }}
          menuItems={[
            {
              name: "Delete",
              onClick: () => {
                getState().setShowConfirmationModal({
                  text: "Delete this task?",
                  onConfirm: () => mutate(),
                });
              },
            },
          ]}
        />
      </CardActionArea>
    </Card>
  );
};

export default Task;
