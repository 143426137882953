import { SyntheticEvent, useEffect, useReducer } from "react";
import { ApiProject, ApiProjectDto } from "../../../api/schema";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import dayjs from "dayjs";
import ModalView from "../../../elements/Modal/ModalView";
import DotsMenu from "../../../elements/DotsMenu";
import { getState } from "../../../store";
import { Box, Chip } from "@mui/material";

const ViewProjects = ({
  project,
  open,
  onClose,
}: {
  project: ApiProject;
  open: boolean;
  onClose: () => void;
}) => {
  const {
    projectId,
    name,
    description,
    dueDate,
    type,
    priority,
    status,
    createdBy,
    created,
    budget,
    archived,
  } = project;

  const queryClient = useQueryClient();
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });
  const { mutate, status: updateProjectStatus } = useMutation(
    (payload: ApiProjectDto) =>
      ApiClient.projects.updateProject(projectId, payload)
  );

  const { mutate: mutateDeleteProject } = useMutation(
    () => ApiClient.projects.deleteProject(projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["projects"] });
        onClose();
      },
    }
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiProjectDto, newState: Partial<ApiProjectDto | ApiProject>) => ({
      ...state,
      ...newState,
    }),
    {
      name: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      teamLeadUsername: "",
      type: "Regular",
      priority: "Medium",
      budget: 0,
    }
  );

  useEffect(() => {
    if (projectId) {
      setFormInput({
        name,
        description,
        dueDate,
        type,
        priority,
        budget,
      });
    }
  }, [projectId]);

  const handleChangeDate = (date: dayjs.Dayjs | null) => {
    if (date) {
      setFormInput({ dueDate: date.format("YYYY-MM-DD") });
      mutate(
        { ...formInput, dueDate: date.format("YYYY-MM-DD") },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["projects"] });
          },
        }
      );
    }
  };

  const handleInput = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (e: SyntheticEvent) => {
    e.preventDefault();

    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["projects"] });
      },
    });
  };

  const handleClearValue = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    // @ts-ignore
    setFormInput({ ...formInput, [name]: value });
  };

  return (
    <ModalView
      type="projects"
      id={projectId}
      project={project}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmitForm}
      onInput={handleInput}
      onChangeDate={handleChangeDate}
      // @ts-ignore
      formInput={formInput}
      createdBy={createdBy}
      created={created}
      status={updateProjectStatus}
      onClearValue={handleClearValue}
      modalType="project"
      panels={[
        {
          el: (
            <Box key={projectId} sx={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
              {archived && <Chip label="Archived" variant="outlined" color='warning' />}
              <DotsMenu
                menuItems={[
                  {
                    name: "Delete",
                    onClick: () => {
                      getState().setShowConfirmationModal({
                        text: "Delete this project?",
                        onConfirm: () => mutateDeleteProject(),
                      });
                    },
                  },
                ]}
              />
            </Box>
          ),
        },
      ]}
    />
  );
};

export default ViewProjects;
