export type Format = "percent" | "currency";
export type Type = "checkbox";

export const tablesDefaults: {
  [key: string]: {
    priority: number;
    columns?: {
      [key: string]:
      | {
        priority?: number;
        value?: number;
        formatter?: Format;
        order?: number;
        formula?: (
          tables: any,
          rows: any,
          rowIndex: any,
          currentRow: any,
          tableName: string,
          columnKey?: string
        ) => number;
        type?: Type;
        editable?: boolean;
      }
      | undefined;
    };
  };
} = {
  "Task Table": {
    priority: 4,
    columns: {
      potentialReward: {
        priority: 1,
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const story =
            tables["Story Table"].rows.find(
              (row: any) => row.storyId === currentRow.storyId
            ) ||
            tables["Reserve Table"].rows.find(
              (row: any) => row.storyId === currentRow.storyId
            );

          const sum = rows?.reduce(
            (acc: number, value: any) => acc + (value.estimatedTaskHours || 0),
            0
          );

          return (
            Math.round(((story?.potentialReward * (currentRow.estimatedTaskHours || 0)) / sum) * 100) / 100
          );
        },
      },
      adjustedHours: {
        priority: 2,
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const {
            estimatedTaskHours,
            submittedHours,
            potentialReward,
            projectManagerApproved,
          } = currentRow;

          let value;

          if (potentialReward === 0) {
            return 0;
          } else {
            if (estimatedTaskHours >= submittedHours) {
              value = estimatedTaskHours;
            } else {
              if (projectManagerApproved) {
                value = submittedHours;
              } else {
                value = estimatedTaskHours;
              }
            }
          }

          return value;
        },
      },
      actualReward: {
        priority: 3,
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const story =
            tables["Story Table"].rows.find(
              (row: any) => row.storyId === currentRow.storyId
            ) ||
            tables["Reserve Table"].rows.find(
              (row: any) => row.storyId === currentRow.storyId
            );

          const sum = rows?.reduce(
            (acc: number, value: any) => acc + (value.adjustedHours || 0),
            0
          );

          return sum === 0
            ? 0
            : (story?.potentialReward * currentRow.adjustedHours) / sum;
        },
      },
      actualRewardWithBonus: {
        priority: 4,
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const { actualReward, adjustedHours } = currentRow;
          const pmCompensation =
            tables["Compensation Table"]?.rows[0]?.compPercentage ||
            tablesDefaults["Compensation Table"]?.columns?.compPercentage?.value;
          const potentialReward =
            tables["Reserve Table"]?.rows[0]?.potentialReward;
          let adjustedHoursSum = 0;
          for (let taskName in tables["Task Table"]) {
            const task = tables["Task Table"][taskName];

            task.rows.forEach((taskRow: any) => {
              if (taskRow.taskId === currentRow.taskId) {
                adjustedHoursSum += currentRow.adjustedHours;
              } else {
                adjustedHoursSum += taskRow.adjustedHours;
              }
            });
          }


          return (
            actualReward +
            (1 - pmCompensation) *
            (potentialReward / adjustedHoursSum) *
            adjustedHours
          );
        },
      },
      taskUnforeseen: {
        type: "checkbox",
        editable: false,
      },
      taskComplete: {
        type: "checkbox",
        editable: false,
      },
      projectManagerApproved: {
        type: "checkbox",
        editable: false,
      },
    },
  },
  "Project Table": {
    priority: 1,
    columns: {
      reserve: {
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const { reservePercentage, budget } = currentRow;
          let value;
          if (!reservePercentage) {
            value = budget * 0.2;
          } else {
            value = budget * Math.min(reservePercentage, 1);
          }

          return value;
        },
      },
      reservePercentage: {
        formatter: "percent",
        value: 0.2,
      },
      budget: {
        formatter: "currency",
      },
      availableBudget: {
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const { budget, reserve } = tables["Project Table"]?.rows[0] || {};
          const { compPercentage } =
            tables["Compensation Table"]?.rows[0] || {};
          const newBudget = budget - reserve;
          const compensation = (compPercentage ||
            tablesDefaults["Compensation Table"]?.columns?.compPercentage?.value)

          return Math.max(
            0,
            newBudget -
            newBudget *
            (compensation > 1 ? 1 : compensation)
          );
        },
      },
    },
  },
  "Compensation Table": {
    priority: 5,
    columns: {
      compPercentage: {
        formatter: "percent",
        value: 0.3,
      },
      compAmount: {
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const { budget, reserve } = tables["Project Table"]?.rows[0] || {};
          const compPercentage = (currentRow.compPercentage || tablesDefaults["Compensation Table"]?.columns?.compPercentage?.value)
          return (
            (budget - reserve) *
            (compPercentage > 1 ? 1 : compPercentage)
          );
        },
      },
    },
  },
  "Payout Table": {
    priority: 6,
    columns: {
      totalReward: {
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const pmCompensation =
            tables["Compensation Table"]?.rows[0]?.compAmount
            || tablesDefaults["Compensation Table"]?.columns?.compAmount?.value;

          let reward = 0;
          for (let taskName in tables["Task Table"]) {
            const task = tables["Task Table"][taskName];

            task.rows.forEach((taskRow: any) => {
              if (taskRow.assignedUserId === currentRow.userId)
                reward += taskRow.actualRewardWithBonus
            });
          }
          const compPercentage = (tables["Compensation Table"]?.rows[0]?.compPercentage
            || tablesDefaults["Compensation Table"]?.columns?.compPercentage?.value)

          const totalReward = reward +
            (currentRow.userId === tables["Compensation Table"]?.rows[0]?.teamLeadId
              && (pmCompensation +
                (tables["Reserve Table"]?.rows[0]?.potentialReward *
                  (compPercentage > 1 ? 1 : compPercentage)
                )
              )
            )

          if (!currentRow.userId) {
            let totalPayout = 0;
            for (let i = 0; i < tables["Payout Table"]?.rows.length - 1; i++) {
              totalPayout += tables["Payout Table"]?.rows[i].totalReward;
            }
            return (
              tables["Project Table"]?.rows[0]?.budget -
              totalPayout
            )
          }

          return (totalReward);
        },
      },
    },
  },
  "Story Table": {
    priority: 2,
    columns: {
      weightPercentage: {
        formatter: "percent",
      },
      verifiedWeightPercentage: {
        priority: 1,
        formatter: "percent",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const sum = rows?.reduce(
            (acc: number, value: any) => acc + value.weightPercentage,
            0
          );

          return sum < 1
            ? currentRow.weightPercentage
            : (1 / sum) * currentRow.weightPercentage;
        },
      },
      potentialReward: {
        priority: 2,
        formatter: "currency",
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          return (
            tables["Project Table"]?.rows[0]?.availableBudget *
            currentRow.verifiedWeightPercentage || 0
          );
        },
      },
    },
  },
  "Reserve Table": {
    priority: 3,
    columns: {
      weightPercentage: {
        formatter: "percent",
      },
      verifiedWeightPercentage: {
        formatter: "percent",
        priority: 1,
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const isBonusRow = currentRow.reserveManagement === "Bonus";

          const sum = rows?.reduce(
            (acc: number, value: any) => acc + value.weightPercentage,
            0
          );

          if (isBonusRow) {
            let unforeseenStoriesSum = rows?.reduce(
              (acc: number, value: any, index: number) =>
                index > 0 && acc + (value.weightPercentage || 0),
              0
            );
            if (unforeseenStoriesSum === 0) {
              return 1;
            } else {
              unforeseenStoriesSum = rows?.reduce(
                (acc: number, value: any) => acc + value.weightPercentage,
                0
              );
              if (unforeseenStoriesSum < 1) {
                return currentRow.weightPercentage + (1 - unforeseenStoriesSum);
              } else if (unforeseenStoriesSum === 1) {
                return currentRow.weightPercentage;
              } else {
                return currentRow.weightPercentage * (1 / unforeseenStoriesSum);
              }
            }
          }

          return sum <= 1
            ? currentRow.weightPercentage
            : (1 / sum) * currentRow.weightPercentage;
        },
      },
      potentialReward: {
        formatter: "currency",
        priority: 2,
        formula: (tables, rows, rowIndex, currentRow, tableName) => {
          const reserve =
            tables["Project Table"]?.rows[0]?.reserve ||
            tablesDefaults["Project Table"]?.columns?.reserve?.value;

          return reserve * currentRow.verifiedWeightPercentage || 0;
        },
      },
    },
  },
};
