import { SyntheticEvent, useRef, useState } from "react";
import {
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";
import { grey } from "@mui/material/colors";

const EditableField = ({
  label,
  typographyProps,
  fieldProps,
  fontSize = 1,
  lineHeight = 1.25,
  textContent,
  content,
  multiline,
  disabled,
  onInput,
  onBlur,
  onClearValue,
}: {
  label: string | null;
  typographyProps?: TypographyProps;
  fieldProps: BaseTextFieldProps;
  fontSize: number;
  textContent?: string;
  content?: string;
  lineHeight?: number;
  multiline?: boolean;
  disabled?: boolean;
  onInput?: (event: SyntheticEvent<any>) => void;
  onBlur?: (event: SyntheticEvent<any>) => void;
  onClearValue?: ({ name, value }: { name: string; value: string }) => void;
}) => {
  const closeButtonRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const [valueFieldBeforeFocus, setFieldBeforeFocus] = useState<{
    name: string;
    value: string;
  } | null>(null);

  const handleClearInput = (evt: SyntheticEvent<any>) => {
    setFocused(false);
    onClearValue && onClearValue(valueFieldBeforeFocus!);
  };

  return (
    <>
      {label && <Typography variant="subtitle2">{label}:</Typography>}
      {focused && !disabled ? (
        <TextField
          autoFocus
          variant="standard"
          fullWidth
          multiline={multiline || true}
          value={content}
          {...fieldProps}
          sx={{
            "& .MuiInputBase-input": { fontSize: `${fontSize}rem`, lineHeight },
          }}
          disabled={disabled}
          onInput={onInput}
          onFocus={(evt) => {
            setFieldBeforeFocus({
              name: evt.target.name,
              value: evt.target.value,
            });
          }}
          onBlur={(event) => {
            if (event.relatedTarget !== closeButtonRef.current) {
              setFocused(false);
              onBlur && onBlur(event);
            }
          }}
        />
      ) : (
        <Typography
          gutterBottom
          {...typographyProps}
          sx={{
            fontSize: `${fontSize}rem`,
            padding: "4px 0 5px",
            mb: 0,
            borderBottom: "1px solid transparent",
            lineHeight,
            overflowWrap: "break-word",
            whiteSpace: (fieldProps.name == "description") ? "pre-wrap" : "",
            ...(!disabled && {
              "&:hover": {
                backgroundColor: grey[800],
                borderRadius: "2px",
                boxShadow: `-1px 0 0px 2px ${grey[800]}`,
              },
            }),
          }}
          onClick={() => setFocused(true)}
        >
          {textContent || content}
        </Typography>
      )}
    </>
  );
};

export default EditableField;
