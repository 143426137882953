import { Box, Button, Stack, Typography } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";
import ApiClient from "../../api/axios.config";
import fileDownload from "js-file-download";
import { getState, useStore } from "../../store";

export interface FileProps {
  name: string;
  attachmentId: number;
  s3Id: string;
}

interface AttachmentsProps {
  type: string;
  id: number;
}

const isImageFile = (fileName: string): boolean => {
  if (fileName) {
    const imageExtensions = ['jpeg', 'jpg', 'png', 'gif'];
    const fileExtension = fileName.split('.').pop();
    return fileExtension ? imageExtensions.includes(fileExtension) : false;
  }
  return false;
};

const Attachments = ({ type, id }: AttachmentsProps) => {
  const [attachments, setAttachments] = useState<FileProps[]>([]);
  const archiveMode = useStore((state) => state.archiveMode)

  useEffect(() => {
    ApiClient.attachments.listAttachments(type, id)
      .then((res) => {
        setAttachments(res.data);
      });
  }, [])

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await ApiClient.attachments.uploadAttachment(type, id, { name: file.name, file: file });
      ApiClient.attachments.listAttachments(type, id).then((res) => {
        setAttachments(res.data);
      });
    }
  };

  const handleDownload = async (file: string, name: string) => {
    fileDownload(file, name);
  }

  const handleDelete = async (id: number, attachmentId: number) => {
    if (!archiveMode) {
      getState().setShowConfirmationModal({
        text: "Delete file?",
        onConfirm: async () => {
          await ApiClient.attachments.deleteAttachment(type, id, attachmentId)
            .then(() => {
              ApiClient.attachments.listAttachments(type, id).then((res) => {
                setAttachments(res.data);
              });
            });
        }
      });
    }
  };

  return (
    <Box sx={{ paddingTop: 2 }}>
      <Button variant="outlined" component='label' sx={{ cursor: 'pointer', marginBottom: 2, display: archiveMode ? 'none' : '' }} startIcon={<AttachFileIcon />}>
        <style>
          {`
              .fileInput {
                display: none;
              }
            `}
        </style>
        <input className="fileInput" type="file" name="attachments"
          accept="image/*,.xlsx,.xls,.xlsm,.doc,.docx,.ppt,.pptx,.txt,.pdf,.zip,.rar,.7z,.tar,.tar.gz,.csv,.sql,.xml,.jar,.ico,.rtf"
          onChange={handleFileChange}
        />
        Attach
      </Button>
      <Stack direction="row" sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.6 }}>
        {attachments.map(file =>
          <Box key={file.name} sx={{
            width: 150,
            height: 116,
            borderRadius: 1,
            display: 'flex column',
            '&:hover .childIcon': {
              opacity: 0.8,
              transition: 200,
            },
          }}>
            <DownloadIcon onClick={() => handleDownload(`https://api.extra-bounty.com/api/${type}/${id}/attachments/${file.attachmentId}`, file.name)} className="childIcon"
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                transform: 'translate(88px, 6px)',
                transition: '200ms',
                borderRadius: 1,
                opacity: 0,
                backgroundColor: '#666666',
              }} />
            <ClearIcon onClick={() => handleDelete(id, file.attachmentId)} className="childIcon"
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                transform: 'translate(118px, 6px)',
                transition: '200ms',
                borderRadius: 1,
                opacity: 0,
                backgroundColor: '#666666',
              }} />
            <Box sx={{
              width: '100%',
              height: '70%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1,
              backgroundColor: isImageFile(file.name) ? 'none' : '#C9A96490',
            }}>
              {isImageFile(file.name)
                ? <img width='100%' height='100%' style={{ objectFit: 'cover', borderRadius: 4, }}
                  src={`https://api.extra-bounty.com/api/${type}/${id}/attachments/${file.attachmentId}`}
                />
                : <InsertDriveFileIcon />}
            </Box>
            <Box sx={{ width: '90%', display: 'flex', alignItems: 'center' }}>
              <Typography sx={{
                cursor: 'pointer',
                color: 'white',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: 14,
                alignItems: 'center',
                padding: 1
              }}
                onClick={() => {
                  handleDownload(`https://api.extra-bounty.com/api/${type}/${id}/attachments/${file.attachmentId}`, file.name);
                }}>
                {file.name}
              </Typography>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Attachments;