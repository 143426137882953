import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { getState, useStore } from "../../store";
import shallow from "zustand/shallow";

const NotificationSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useStore((state) => state.notifications, shallow);

  useEffect(() => {
    if (notifications.length) {
      notifications.forEach(({ message, variant }) => {
        enqueueSnackbar(message, { variant });
      });
      getState().removeNotifications();
    }
  }, [notifications]);

  return <></>;
};

export default NotificationSnackbar;
