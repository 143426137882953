import React, { SyntheticEvent, useEffect, useReducer, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { ApiStory, ApiStoryDto } from "../../../api/schema";
import dayjs from "dayjs";
import ModalView from "../../../elements/Modal/ModalView";
import DotsMenu from "../../../elements/DotsMenu";
import { getState } from "../../../store";
import { Box, Chip } from "@mui/material";

const ViewStory = ({
  story,
  open,
  onClose,
}: {
  story: ApiStory;
  open: boolean;
  onClose: () => void;
}) => {
  const {
    storyId,
    title,
    description,
    dueDate,
    weightPercentage,
    type,
    priority,
    createdBy,
    created,
    assignedUser,
    project,
    archived
  } = story || {};

  const queryClient = useQueryClient();
  const { mutate, status } = useMutation((payload: ApiStoryDto) =>
    ApiClient.stories.updateStory(storyId, payload)
  );

  const { mutate: mutateDeleteStory } = useMutation(
    () => ApiClient.stories.deleteStory(storyId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["stories"] });
        onClose();
      },
    }
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiStoryDto, newState: Partial<ApiStoryDto & ApiStory>) => ({
      ...state,
      ...newState,
    }),
    {
      title: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      assignedUserUsername: "",
      type: "Regular",
      priority: "Medium",
      weightPercentage: 0,
      projectId: 0,
    }
  );

  useEffect(() => {
    if (storyId) {
      setFormInput({
        title,
        description,
        dueDate,
        assignedUserUsername: assignedUser?.username,
        type,
        priority,
        weightPercentage,
        projectId: project.projectId,
      });
    }
  }, [storyId]);

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleChangeDate = (date: dayjs.Dayjs | null) => {
    if (date) {
      setFormInput({ dueDate: date.format("YYYY-MM-DD") });
      mutate(
        { ...formInput, dueDate: date.format("YYYY-MM-DD") },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["stories"] });
          },
        }
      );
    }
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();

    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["stories"] });
      },
    });
  };

  const handleClearValue = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    // @ts-ignore
    setFormInput({ ...formInput, [name]: value });
  };

  return (
    <ModalView
      type='stories'
      id={storyId}
      story={story}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmitForm}
      onInput={handleInput}
      onChangeDate={handleChangeDate}
      // @ts-ignore
      formInput={formInput}
      createdBy={createdBy}
      created={created}
      status={status}
      onClearValue={handleClearValue}
      modalType="story"
      panels={[
        {
          el: (
            <Box key={storyId} sx={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
              {archived && <Chip label="Archived" variant="outlined" color='warning'/>}
              <DotsMenu
                menuItems={[
                  {
                    name: "Delete",
                    onClick: () => {
                      getState().setShowConfirmationModal({
                        text: "Delete this story?",
                        onConfirm: () => mutateDeleteStory(),
                      });
                    },
                  },
                ]}
              />
            </Box>
          ),
        },
      ]}
    />
  );
};

export default ViewStory;
