// @ts-nocheck
import { useState } from "react";
import { Format } from "../pages/Finance/tableDefaults";

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function convertTo(o, toCase = false) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = convertTo(value, toCase);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = toCase
          ? camelToSnakeCase(origKey)
          : (
              origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
            ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = convertTo(value, toCase);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export const usePrevious = (value) => {
  const [state, setState] = useState({
    value: value,
    prev: null,
  });

  const current = state.value;

  if (value !== current) {
    setState({
      value: value,
      prev: current,
    });
  }

  return state.prev;
};

export const formatNumber = (number: number, formatType: Format) => {
  let options = {};

  if (formatType === "currency") {
    options = { };
  }

  if (formatType === "percent") {
    options = { style: "percent" };
  }

  return new Intl.NumberFormat("en-US", options).format(number);
};
