import { Api } from "./schema";
import { getState, setState } from "../store";
import axios, { AxiosError } from "axios";

const ApiClient = new Api({
  baseURL: import.meta.env.DEV
    ? "https://api.extra-bounty.com/api/"
    : "https://api.extra-bounty.com/api/",
});

const authToken = getState().token;

if (authToken) {
  ApiClient.instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authToken}`;
}

ApiClient.instance.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    getState().setError(error);
    return Promise.reject(error);
  }
);

ApiClient.instance.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      setState({ token: null });
      return (location.href = "/login");
    }
    if (axios.isAxiosError(error)) {
      getState().setError(error);
    }
    return Promise.reject(error);
  }
);

export default ApiClient;
