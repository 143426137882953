import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Requests from "./components/Requests";


const Dashboard = () => {


  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <Typography variant="h5">Dashboard</Typography>
      <Grid container spacing={4} columns={{ xs: 6, xl: 12 }}>
        <Grid item xs={6}>
          <Requests />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
