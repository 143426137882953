import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useStore } from "../store";

const InnerHeader = ({
  title,
  onNewProject,
  withoutMargin,
}: {
  title: string;
  onNewProject?: () => void;
  withoutMargin?: boolean;
}) => {
  const user = useStore((state) => state.user);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      marginBottom={withoutMargin ? 0 : 4}
    >
      <Typography variant="h5">{title}</Typography>
      <Button variant="contained" onClick={onNewProject} disabled={title === 'Projects' && (user.role === "Developer" ? true : false)}>
        Create New
      </Button>
    </Stack>
  );
};

export default InnerHeader;
