import React from "react";
import {
  Box,
  Box as MuiBox,
  BoxProps,
  IconButton,
  Modal as MuiModal,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

const StyledBox = styled(MuiBox) <BoxProps>
  `
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${grey[900]};
  border-radius: 4px;
  padding: 2rem;
  max-height: 85vh;  // Set the maximum height
  overflow-y: auto;  // Enable scrolling
`;


const StyledCloseButton = styled(IconButton)`
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${grey[900]};
  padding: 5px;
  color: ${grey[500]};
  &:hover {
    backgroundColor: ${grey[800]};
  }
`;

const Modal = ({
  open,
  onClose,
  children,
  modalWidth,
  panels,
}: {
  open: boolean;
  onClose: () => void;
  modalWidth?: number;
  children: React.ReactElement;
  panels?: {
    el: React.ReactElement;
  }[];
}) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <StyledBox width={modalWidth}>
        {panels?.length ?
          <Box display="flex" alignItems="end" marginBottom="20px">
            <>
              {panels.map((panel) => panel.el)}
              <StyledCloseButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </StyledCloseButton>
            </>
          </Box>
          :
          <StyledCloseButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              backgroundColor: grey[900],
              right: 10,
              top: 10,
            }}
          >
            <CloseIcon />
          </StyledCloseButton>
        }
        {children}
      </StyledBox>
    </MuiModal>
  );
};

export default Modal;
