import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { getState, useStore } from "../store";
import { useState } from "react";

const ConfirmDialog = () => {
  const confirmationModal = useStore((state) => state.confirmationModal);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    getState().removeConfirmationModal();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={Boolean(confirmationModal.text)}
        keepMounted
        onClose={getState().removeConfirmationModal}
        sx={{ zIndex: 9999 }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{confirmationModal.text}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              if (confirmationModal.onConfirm) {
                confirmationModal.onConfirm();
              }
              getState().removeConfirmationModal();
            }}
          >
            Yes
          </Button>
          <Button onClick={getState().removeConfirmationModal}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
