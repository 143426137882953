import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   // light: "#484848",
    //   main: "#ab47bc",
    //   // dark: "#000000",
    //   // contrastText: "#000",
    // },
    // secondary: {
    //   // light: "#ff6090",
    //   main: "#f8bbd0",
    //   // dark: "#b0003a",
    //   // contrastText: "#000",
    // },
    // text: {
    //   primary: "#ffffff",
    //   secondary: "#c48b9f",
    // },
  },
  typography: {
    subtitle2: {
      fontWeight: 400,
      fontSize: "0.775rem",
      lineHeight: "23px",
      letterSpacing: "0.15px",
      color: grey[400],
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
  },
});
