import { EditorProps } from "react-data-grid";
import { Row } from "../pages/Finance/Finance";
import React, { useState } from "react";
import { css } from "@emotion/react";
import { Format, tablesDefaults } from "../pages/Finance/tableDefaults";

const textEditorInputStyles = css`
  appearance: none;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);
  font-family: inherit;
  font-size: var(--rdg-font-size);

  &:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
`;

export const TableTextEditor = ({
  column,
  row,
  onRowChange,
  onClose,
  stripNumbers,
  tableName,
  formatter,
}: EditorProps<Row> & {
  stripNumbers: boolean;
  tableName: string;
  formatter?: Format;
}) => {
  const defaults = tablesDefaults[tableName]?.columns?.[column.key];
  const [value, setValue] = useState<number>(
    row[column.key] || defaults?.value || 0
  );
  const [error, setError] = useState<boolean>(false);

  const floatRegex = /^[-+]?\d*\.?\d*$/;

  const handleSubmitValue = (event: React.KeyboardEvent | React.FocusEvent) => {
    // @ts-ignore
    let value = event.target?.value;
    value = stripNumbers ? Number(value) : value;

    if (stripNumbers && !floatRegex.test(value)) {
      setError(true);
      return;
    }
    setError(false);
    onRowChange(
      { ...row, [column.key]: formatter === "percent" ? value / 100 : value },
      true
    );
  };

  return (
    <input
      css={textEditorInputStyles}
      ref={(input) => {
        input?.focus();
      }}
      value={formatter === "percent" ? Number((value * 100).toFixed(3)) : value}
      onInput={(event) => {
        // @ts-ignore
        floatRegex.test(event.target.value);
      }}
      onChange={(event) => {
        const newValue = Number(event.target.value);
        setValue(
          formatter === "percent"
            ? newValue / 100
            : newValue
        );
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmitValue(event);
        }
      }}
      onBlur={(event) => {
        handleSubmitValue(event);
        onClose(true);
      }}
    />
  );
};
