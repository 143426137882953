import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  Badge,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useLocation, useRoute } from "wouter";
import { ApiProject } from "../../../api/schema";

const Filters = ({
  projects,
  onOpenToAllChange,
}: {
  projects?: ApiProject[];
  onOpenToAllChange: (value: boolean) => void;
}) => {
  const [location, setLocation] = useLocation();
  const [openToAll, setOpenToAll] = useState(false);
  const [match, params] = useRoute("stories/:projectId");
  const [selectedProject, setSelectedProject] = useState(
    params?.projectId || "all"
  );

  useEffect(() => {
    if (!params?.projectId) {
      setOpenToAll(false);
      setSelectedProject("all");
    }
  }, [params?.projectId]);

  const handleSelectProject = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const newValue = target.value;

    setSelectedProject(newValue);
    if (newValue === "all") {
      setLocation(`stories`);
      return;
    }
    setLocation(`stories/${newValue}`);
  };

  const handleOpenToAllChange = (evt: SyntheticEvent<any>) => {
    const target = evt.target as HTMLInputElement;
    const newValue = target.checked;

    setOpenToAll(newValue);
    onOpenToAllChange(newValue);
  };

  return (
    <Stack direction="row" marginBottom={4} spacing={2}>
      {projects && (
        <TextField
          select
          label="Project"
          name="project"
          defaultValue={0}
          value={selectedProject}
          onChange={handleSelectProject}
          margin="normal"
          size="small"
          sx={{ width: "160px" }}
          SelectProps={{
            renderValue: (selected) => {
              return (
                projects.find((p) => p.projectId === Number(selectedProject))
                  ?.name || "All"
              );
            },
          }}
        >
          {[{ projectId: "all", name: "All", stories: null }, ...projects].map(
            (project) => (
              <MenuItem key={project.projectId} value={project.projectId}>
                {project.name}
                <Badge
                  badgeContent={project.stories?.length && project.stories?.length - 1}
                  color="primary"
                  sx={{
                    width: "20px",
                    marginLeft: "auto",
                  }}
                />
              </MenuItem>
            )
          )}
        </TextField>
      )}

      {/*<FormControlLabel*/}
      {/*  sx={{ marginTop: "10px!important" }}*/}
      {/*  control={*/}
      {/*    <Checkbox*/}
      {/*      size="small"*/}
      {/*      checked={openToAll}*/}
      {/*      onChange={handleOpenToAllChange}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  label="Open to all"*/}
      {/*/>*/}
    </Stack>
  );
};

export default Filters;
