import React, { SyntheticEvent, useEffect, useReducer } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { ApiTask, ApiTaskDto } from "../../../api/schema";
import dayjs from "dayjs";
import ModalView from "../../../elements/Modal/ModalView";
import DotsMenu from "../../../elements/DotsMenu";
import { getState } from "../../../store";
import { Box, Chip } from "@mui/material";

const ViewTask = ({
  task,
  open,
  onClose,
}: {
  task: ApiTask;
  open: boolean;
  onClose: () => void;
}) => {
  const {
    taskId,
    title,
    description,
    dueDate,
    estimatedHours,
    type,
    priority,
    status,
    createdBy,
    created,
    assignedUser,
    story,
    archived,
  } = task || {};

  const queryClient = useQueryClient();
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () => ApiClient.users.listAllUsers().then((res) => res.data),
  });
  const { mutate, status: updateTaskStatus } = useMutation(
    (payload: ApiTaskDto) => ApiClient.tasks.updateTask(taskId, payload)
  );

  const { mutate: mutateDeleteTask } = useMutation(
    () => ApiClient.tasks.deleteTask(taskId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
        onClose();
      },
    }
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiTaskDto, newState: Partial<ApiTaskDto & ApiTask>) => ({
      ...state,
      ...newState,
    }),
    {
      title: "",
      description: "",
      dueDate: dayjs().format("YYYY-MM-DD"),
      assignedUserUsername: "",
      type: "Regular",
      priority: "Medium",
      estimatedHours: 0,
      storyId: 0,
    }
  );

  useEffect(() => {
    if (taskId) {
      setFormInput({
        title,
        description,
        dueDate,
        assignedUserUsername: assignedUser?.username,
        type,
        priority,
        estimatedHours,
        storyId: story.storyId,
      });
    }
  }, [taskId]);

  const handleChangeDate = (date: dayjs.Dayjs | null) => {
    if (date) {
      setFormInput({ dueDate: date.format("YYYY-MM-DD") });
      mutate(
        { ...formInput, dueDate: date.format("YYYY-MM-DD") },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["tasks"] });
          },
        }
      );
    }
  };

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();

    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["tasks"] });
      },
    });
  };

  const handleClearValue = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    // @ts-ignore
    setFormInput({ ...formInput, [name]: value });
  };

  return (
    <ModalView
      type='tasks'
      id={taskId}
      task={task}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmitForm}
      onInput={handleInput}
      onChangeDate={handleChangeDate}
      // @ts-ignore
      formInput={formInput}
      createdBy={createdBy}
      created={created}
      status={updateTaskStatus}
      onClearValue={handleClearValue}
      modalType="task"
      panels={[
        {
          el: (
            <Box key={taskId} sx={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
              {archived && <Chip label="Archived" variant="outlined" color='warning' />}
              <DotsMenu
                menuItems={[
                  {
                    name: "Delete",
                    onClick: () => {
                      getState().setShowConfirmationModal({
                        text: "Delete this task?",
                        onConfirm: () => mutateDeleteTask(),
                      });
                    },
                  },
                ]}
              />
            </Box>
          ),
        },
      ]}
    />
  );
};

export default ViewTask;
