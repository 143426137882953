import React, { SyntheticEvent } from "react";
import {
  Box,
  Button,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import { ApiProject } from "../../../api/schema";
import { useLocation } from "wouter";
import DotsMenu from "../../../elements/DotsMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { getState } from "../../../store";
import dayjs from "dayjs";
import {
  grey,
  orange,
  lightBlue
} from "@mui/material/colors";

interface ProjectsItem {
  storiesCount?: number;
  onClick: (evt: SyntheticEvent) => void;
}

const ProjectsItem = ({
  name,
  dueDate,
  storiesCount,
  budget,
  projectId,
  archived,
  onClick,
}: ProjectsItem & Partial<ApiProject>) => {
  const [location, setLocation] = useLocation();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    () => ApiClient.projects.deleteProject(projectId!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["projects"] });
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "24px",
        width: "290px",

        backgroundColor: grey[900],
        borderRadius: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography sx={{
        fontSize: "18px",
        marginBottom: "16px",
        height: "3em",

        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        overflowWrap: "break-word",
        overflow: 'clip',
      }}>
        {name}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>

        <Stack direction="column" gap="8px">
          <Typography sx={{ fontSize: "14px" }} color={grey[400]}>
            Due
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {dayjs(dueDate).format('MMM D, YYYY')}
          </Typography>
        </Stack>

        <Stack direction="column" gap="8px">
          <Typography sx={{ fontSize: "14px" }} color={grey[400]}>
            Budget
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {budget ? "" + budget : "not set"}
          </Typography>
        </Stack>

        <Stack direction="column" gap="8px">
          <Typography sx={{ fontSize: "14px" }} color={grey[400]}>
            Stories
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {storiesCount}
          </Typography>
        </Stack>

      </Box>

      <Box sx={{ display: "flex", gap: "16px" }}>

        <Button onClick={onClick} variant="contained" sx={{
          padding: "10px",
          height: "32px",
          display: "flex",
          fontSize: "10px",
          width: "100%",
        }}>
          view Project
        </Button>

        <Button onClick={() => setLocation(`stories/${projectId}`)} variant="outlined" sx={{
          padding: "10px",
          height: "32px",
          display: "flex",
          fontSize: "10px",
          width: "100%",
        }}>
          view Stories
        </Button>

      </Box>

    </Box>
  );
};

export default ProjectsItem;
