import { FormatterProps } from "react-data-grid";
import { Row } from "../pages/Finance/Finance";
import { Format, tablesDefaults } from "../pages/Finance/tableDefaults";
import { formatNumber } from "../helpers/helpers";
import React from "react";

export const CustomFormatter = ({
  column,
  row,
  isCellSelected,
  isCellEditable,
  onRowChange,
  tableName,
  formatter,
}: FormatterProps<Row, unknown> & {
  tableName: string;
  formatter?: Format;
}) => {
  const defaults = tablesDefaults[tableName]?.columns?.[column.key];
  const value = row[column.key] || defaults?.value || 0;
  const formattedValue = formatter ? formatNumber(value, formatter) : value;

  return (
    <>
      {formattedValue}
      {/*For debug purpose. Don't remove*/}
      {/*<pre style={{ position: "absolute", bottom: "-20px", right: 0 }}>*/}
      {/*  {column.key}*/}
      {/*</pre>*/}
    </>
  );
};
