import { SyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import "react-data-grid/lib/styles.css";
import { Column, RowsChangeData } from "react-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../api/axios.config";
import { useLocation, useRoute } from "wouter";
import TabPanel from "../../elements/TabPanel";
import Table from "./components/Table";
import { tablesDefaults } from "./tableDefaults";
import { getState, useStore } from "../../store";
import { ApiProject } from "../../api/schema";
import fileDownload from "js-file-download";

export interface SummaryRow {
  [key: string]: string | number;
}

export type TableType = {
  columns: readonly Column<Row, SummaryRow>[];
  rows: Row[];
};

type TablesType = Record<string, TableType | Record<string, TableType>>;

// Row do not have id value, so cant get a unique key!
export type Row = Record<string, number>;

const tableSort = (tables: TablesType) => {
  let sortedTables = [];
  for (let i = 0; i < Object.entries(tables).length; i++) {
    sortedTables.push(
      Object.entries(tables)
        .find(table => tablesDefaults[table[0]]?.priority === i + 1) || Object.entries(tables)[i]
    )
  }
  return sortedTables;
}

const formatTables = (
  tables: TablesType,
  outerIndex?: number,
  outerTableName?: string
) => {
  let newTables;

  const formatTable = (
    tables: TablesType,
    fullTable?: TablesType
  ) =>
    tableSort(tables)
      .reduce(
        (
          acc: Record<string, TableType | Record<string, TableType>>,
          [tableName, table],
          i
        ) => {
          const tableDefault =
            tablesDefaults[tableName]?.columns ||
            tablesDefaults["Task Table"]?.columns;

          acc = tables;

          if (table.rows && table.columns) {
            let rows = tables[tableName].rows as Row[];
            let end = false;
            if (outerTableName === tableName && outerIndex) {
              const item = rows.splice(outerIndex, 1)[0];
              rows.unshift(item);
            }
            acc[tableName].rows = rows.reduce(
              (innerAcc: Row[], currentRow, index) => {
                innerAcc[index] = (table.rows as Row[])[index] || {};

                Object.entries(currentRow)
                  ?.sort((prevT, nextT) => {
                    const a = tableDefault?.[prevT[0]];
                    const b = tableDefault?.[nextT[0]];
                    if (a?.priority != null && b?.priority != null) {
                      return a?.priority - b?.priority;
                    }

                    if (a?.priority == null && b?.priority != null) {
                      return -1;
                    }

                    if (a?.priority != null && b?.priority == null) {
                      return 1;
                    }

                    return 0;
                  })
                  .forEach(([cellName, value], cellIndex) => {
                    const defaultValue = tableDefault?.[cellName];
                    if (defaultValue) {
                      if (defaultValue?.formula) {
                        innerAcc[index][cellName] =
                          defaultValue?.formula(
                            fullTable,
                            table.rows,
                            index,
                            currentRow,
                            tableName
                          ) || 0;
                      } else {
                        if (defaultValue.formatter === "percent" && cellName != "weightPercentage") {
                          if (value > 1) {
                            currentRow[cellName] = 1;
                          }
                        }
                      }
                    } else if (value === null) {
                      if (cellName === "userId") {
                        // @ts-ignore
                        innerAcc[index][cellName] = null;
                      } else {
                        innerAcc[index][cellName] = 0;
                      }
                    }
                  });

                if (index === rows.length - 1) {
                  end = true;
                }
                return innerAcc;
              },
              []
            );

            if (end && outerTableName === tableName && outerIndex) {
              const item = (acc[tableName].rows as Row[]).shift();
              (acc[tableName].rows as Row[]).splice(outerIndex, 0, item!);
            }
          } else {
            acc[tableName] = formatTable(
              table as Record<string, TableType>,
              fullTable
            ) as Record<string, TableType>;
          }

          return {
            ...tables,
            ...acc,
          };
        },
        {}
      );
  newTables = formatTable(tables, tables);
  formatTable(newTables, tables);
  return newTables;
};

const Finance = () => {
  const [location, setLocation] = useLocation();
  const [tab, setTab] = useState(0);
  const [match, params] = useRoute(`rewards/:projectId`);
  const [selectedProject, setSelectedProject] = useState<ApiProject | null>(null);
  const [projectSelectInputValue, setProjectSelectInputValue] = useState("");
  const [tables, setTables] = useState<TablesType | null>(null);
  const archiveMode = useStore((state) => state.archiveMode);

  const renderTables = (tables: TablesType) => {
    const tablesArray = tables && tableSort(tables);

    return tablesArray?.map(([tableName, table], i) => {
      return (
        // some key errors here
        <TabPanel key={tableName} value={tab} index={i}>
          {table.columns && table.rows ? (
            <Table
              table={table as TableType}
              key={tableName}
              innerTableName={tableName}
              onTableChange={handleTableChange}
              disabled={archiveMode}
            />
          ) : (
            Object.entries(table).map(([innerTableName, table], i) => (
              (table.rows.length ?
                <Table
                  outerTableName={tableName}
                  key={innerTableName}
                  table={table as TableType}
                  innerTableName={innerTableName}
                  onTableChange={handleTableChange}
                  disabled={archiveMode}
                /> : <></>
              )
            ))
          )}
        </TabPanel>
      );
    });
  };

  const { mutate: mutateSave } = useMutation(
    (payload: TablesType) =>
      ApiClient.projects
        // @ts-ignore
        .savePanel(Number(params?.projectId), payload)
        .then((res) => res.data),
    {
      onSuccess: () => {
        getState().setNotification("Project saved", "success");
      },
    }
  );

  const { mutate: mutateStart } = useMutation(
    () =>
      ApiClient.projects
        .startProject(Number(params?.projectId))
        .then((res) => res.data),
    {
      onSuccess: () => {
        getState().setNotification("Project started", "success");
        updateProjects();
      },
    }
  );

  const queryClient = useQueryClient();

  const { data: projects } = useQuery({
    queryKey: ["projects"],
    queryFn: () => ApiClient.projects.listAllProjects().then((res) => res.data),
  });

  const { data: archivedProjects } = useQuery({
    queryKey: ["archivedProjects"],
    queryFn: () => ApiClient.projects.listAllProjects(true).then((res) => res.data),
  });

  const updateProjects = () => {
    queryClient.invalidateQueries({ queryKey: ["projects"] });
    queryClient.invalidateQueries({ queryKey: ["archivedProjects"] });
  }

  const handleSaveTables = () => {
    if (tables) {
      mutateSave(tables);
    }
  };

  const handleStartProject = () => {
    getState().setShowConfirmationModal({
      text: "Are you sure you have allocated the budget and want to launch the project?",
      onConfirm: () => {
        mutateStart()
      },
    });
  };

  const handleFinishProject = (projectId: number) => {
    getState().setShowConfirmationModal({
      text: `Are you sure you want to finish this project?
      Finishing project will generate CSV file with payout information for all users.
      Later you will be able to download this file in Archive.`,
      onConfirm: () => {
        ApiClient.projects.finishProject(projectId, tables).then((res) => res.data)
          .then(() => {
            getState().setNotification("Project finished", "success");
            updateProjects();
          })
          .catch(error => {
            getState().setNotification(error.response.data, "error");
          })
      },
    });
  };

  const handleArchiveProject = (projectId: number) => {
    getState().setShowConfirmationModal({
      text: "Are you sure you want to archive this project?",
      onConfirm: () => {
        ApiClient.projects.archiveProject(projectId).then((res) => res.data)
          .then(() => {
            getState().setNotification("Project archived", "success");
            updateProjects();
          })
      },
    });
  };

  const handleCSVDownload = (project: ApiProject | null) => {
    if (project) {
      ApiClient.projects.getPayout(project.projectId)
        .then((res) => {
          fileDownload(res.data, project.name + ".csv");
        });
    }
  };

  const updateFinancePanel = (project: ApiProject | null) => {
    if (project) {
      ApiClient.projects.getPanel(project.projectId)
        .then((res) => {
          // @ts-ignore
          setTables(formatTables(res.data));
        });
    } else {
      setTables(null);
    }
  }

  const store = getState();
  const checkPermission = () => {
    const project = (archiveMode ? archivedProjects : projects)
      ?.find((project) => project.projectId === Number(selectedProject?.projectId))
    if (
      project?.owner.userId === store.user.userId ||
      project?.teamLead?.userId === store.user.userId ||
      store.user.role === "Admin"
    ) return true;
    return false;
  }

  let firstPageUpdate = true;

  useEffect(() => {
    if (!firstPageUpdate) {
      setSelectedProject(null);
      setLocation('rewards');
      setTables(null);
    }
  }, [archiveMode]);

  useEffect(() => {
    if (Number(params?.projectId)) {
      const urlProject =
        (archiveMode ? archivedProjects : projects)
          ?.find(project => project.projectId === Number(params?.projectId))
        || null
      updateFinancePanel(urlProject);
      setSelectedProject(urlProject);
    }
  }, [projects, archivedProjects]);

  useEffect(() => {
    if (!Number(params?.projectId)) {
      setSelectedProject(null);
      setTables(null);
    }
  }, [params]);

  useLayoutEffect(() => {
    if (firstPageUpdate)
      firstPageUpdate = false;
  });

  const handleTableChange = (
    rows: Row[],
    data: RowsChangeData<Row, SummaryRow>,
    tableName: string
  ) => {
    if (tables) {
      setTables(() => {
        const searchRows = (tables: TablesType) => {
          let newTables: TablesType = {};
          for (const key in tables) {
            if (typeof tables[key] === "object" && tables[key] !== null) {
              const table = tables[key] as TableType;
              if (table.columns && table.rows) {
                if (tableName === key) {
                  newTables[key] = {
                    ...table,
                    rows: rows,
                  };
                } else {
                  newTables[key] = table;
                }
              } else {
                newTables[key] = {
                  ...tables[key],
                  ...searchRows(tables[key] as Record<string, TableType>),
                } as Record<string, TableType>;
              }
            } else {
              newTables[key] = tables[key];
            }
          }
          return newTables;
        };

        return formatTables(searchRows(tables), data.indexes[0], tableName);
      });
    }
  };

  return (
    <>
      <Typography variant="h5">Rewards</Typography>
      <Stack direction="row" spacing={2}
        sx={{
          display: 'flex',
          marginTop: 2,
          marginBottom: 1,
          justifyContent: 'space-between',
        }}
      >
        {(archiveMode ? archivedProjects : projects) && (
          <Autocomplete
            sx={{ minWidth: 180 }}
            options={(archiveMode ? archivedProjects : projects) || []}
            value={selectedProject}
            size="small"
            onChange={(event: SyntheticEvent, newValue: ApiProject | null) => {
              setSelectedProject(newValue);
              if (newValue) {
                setLocation(`rewards/${newValue?.projectId}`);
              } else {
                setLocation('rewards');
              }
              updateFinancePanel(newValue);
            }}
            inputValue={projectSelectInputValue}
            onInputChange={(event, newValue) => {
              setProjectSelectInputValue(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
              return true
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => {
              return <TextField label='Select project' {...params} />;
            }}
          />
        )}
        <Stack direction='row' spacing={2}>
          <Button
            onClick={handleSaveTables}
            variant="outlined"
            disabled={archiveMode || !selectedProject}
            color="success"
            sx={{
              alignSelf: "center",
            }}
          >
            Save project
          </Button>
          <Button
            onClick={handleStartProject}
            color="info"
            variant="outlined"
            disabled={selectedProject?.started || archiveMode || !selectedProject}
            sx={{
              alignSelf: "center",
            }}
          >
            Start project
          </Button>
          <Button
            onClick={() => handleArchiveProject(Number(params?.projectId))}
            color="warning"
            variant="outlined"
            disabled={archiveMode || !checkPermission() || !selectedProject}
            sx={{
              alignSelf: "center",
            }}
          >
            Archive project
          </Button>
          <Button
            onClick={() => handleFinishProject(Number(params?.projectId))}
            color="error"
            variant="outlined"
            disabled={archiveMode || !checkPermission() || !selectedProject}
            sx={{
              alignSelf: "center",
            }}
          >
            Finish project
          </Button>
          <Button
            onClick={() => handleCSVDownload(selectedProject)}
            color="success"
            variant="outlined"
            disabled={!archiveMode || !selectedProject || !selectedProject.finished}
            sx={{
              alignSelf: "center",
              display: archiveMode ? "visible" : "none"
            }}
          >
            Download CSV
          </Button>
        </Stack>
      </Stack>
      <Tabs
        value={tab}
        onChange={(e, newTab) => setTab(newTab)}
        sx={{ mb: "32px" }}
      >
        {tables &&
          tableSort(tables)?.map(([tableName, table], i) => (
            <Tab key={tableName} label={tableName} />
          ))}
      </Tabs>
      {tables && renderTables(tables)}
    </>
  );
};

export default Finance;
