import React, { SyntheticEvent } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
} from "@mui/material";
import { getState, setState, useStore } from "../../store";
import { useLocation } from "wouter";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "../../elements/Avatar";
import InventoryIcon from '@mui/icons-material/Inventory';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { Typography } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const NavBar = () => {
  const [location, setLocation] = useLocation();
  const user = useStore((state) => state.user);
  const showSidebar = useStore((state) => state.showSidebar);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setState({ token: null });
    setLocation("/login");
  };

  const handleSidebar = () => {
    setState({ showSidebar: !showSidebar });
  };

  const archiveMode = useStore((state) => state.archiveMode);
  const handleArchiveMode = (event: SyntheticEvent, newAlignment: boolean) => {
    if (newAlignment !== null) {
      setState({ archiveMode: newAlignment })
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ bgcolor: "#1e1e1e", backgroundImage: "none" }}
      >
        <Toolbar
          sx={{
            ml: showSidebar ? getState().sidebar.width + "px" : "0",
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <IconButton onClick={handleSidebar}>
            {showSidebar
              ? <KeyboardDoubleArrowLeftIcon />
              : <KeyboardDoubleArrowRightIcon />
            }
          </IconButton>
          <Stack direction='row' gap={4}>
            <ToggleButtonGroup
              value={archiveMode}
              exclusive
              onChange={handleArchiveMode}
              size="small"
            >
              <ToggleButton value={false}>
                <Stack direction='row' gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InventoryIcon />
                  <Typography variant='body2' sx={{ textTransform: 'none' }}>
                    Normal
                  </Typography>
                </Stack>
              </ToggleButton>
              <ToggleButton value={true}>
                <Stack direction='row' gap={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  <BusinessCenterIcon />
                  <Typography variant='body2' sx={{ textTransform: 'none' }}>
                    Archived
                  </Typography>
                </Stack>
              </ToggleButton>
            </ToggleButtonGroup>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: "auto" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                name={user.name}
                lastName={user.lastName}
                sx={{
                  width: 32,
                  height: 32,
                  fontSize: "18px",
                }}
              />
            </IconButton>
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setLocation("profile");
                setAnchorEl(null);
              }}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
