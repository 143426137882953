import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { getState, useStore } from "../store";

const ConfirmFormDialog = (
  // text: string,
  // onConfirm: (message: string) => void,
) => {
  // const [open, setOpen] = useState(true);
  const [confirmText, setConfirmText] = useState('');
  const confirmFormModal = useStore((state) => state.confirmFormModal);

  const handleClose = () => {
    getState().removeConfirmFormModal();
    setConfirmText('');
    // setOpen(false);
  };

  const handleConfirm = () => {
    if (confirmFormModal.onConfirm) {
      confirmFormModal.onConfirm(confirmText);
    }
    handleClose();
    // setOpen(false);
  };

  return (
    <Dialog
      open={Boolean(confirmFormModal.text)}
      onClose={handleClose}
      sx={{ zIndex: 1300 }}
    >
      <DialogTitle>{confirmFormModal.text}</DialogTitle>
      <DialogContent>
        <TextField
          value={confirmText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmText(event.target.value);
          }}
          autoFocus
          multiline
          id="confirmTextField"
          fullWidth
          minRows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button color='success' fullWidth onClick={handleConfirm}>Confirm</Button>
        <Button color='error' fullWidth onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmFormDialog;
