import React, { SyntheticEvent, useReducer } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../api/axios.config";
import { ApiUserDto } from "../../../api/schema";
import Modal from "../../../elements/Modal/Modal";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import users from "../Users";

const NewUser = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation((payload: ApiUserDto) =>
    ApiClient.users.addNewUser(payload)
  );
  const [formInput, setFormInput] = useReducer(
    (state: ApiUserDto, newState: Partial<ApiUserDto>) => ({
      ...state,
      ...newState,
    }),
    {
      name: "",
      lastName: "",
      email: "",
      username: "",
      /** @pattern Admin|Manager|Developer */
      role: "Developer",
      /** @pattern FullTime|Contractor */
      employmentStatus: "FullTime",
    }
  );

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmitForm = (evt: SyntheticEvent) => {
    evt.preventDefault();
    mutate(formInput, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        onClose();
      },
    });
  };

  return (
    <Modal open={open} onClose={onClose} modalWidth={400}>
      <Box component="form" onSubmit={handleSubmitForm}>
        <Typography variant="h5" align="center" gutterBottom>
          Create new user
        </Typography>

        <TextField
          label="User name"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          name="name"
          onInput={handleInput}
        />
        <TextField
          label="Lastname"
          variant="outlined"
          fullWidth
          margin="normal"
          name="lastName"
          onInput={handleInput}
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email"
          required
          name="email"
          onInput={handleInput}
        />
        <TextField
          label="Login username"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          name="username"
          onInput={handleInput}
        />
        <TextField
          label="Login password"
          variant="outlined"
          type="password"
          fullWidth
          required
          margin="normal"
          name="password"
          onInput={handleInput}
        />
        <TextField
          select
          label="Role"
          required
          name="role"
          defaultValue="Developer"
          value={formInput.role}
          onChange={handleInput}
          fullWidth
          margin="normal"
        >
          {["Admin", "Manager", "Developer"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Employment Status"
          required
          name="employmentStatus"
          defaultValue="FullTime"
          value={formInput.employmentStatus}
          onChange={handleInput}
          fullWidth
          margin="normal"
        >
          {["FullTime", "Contractor"].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <Stack
          direction="column"
          justifyContent="space-around"
          mt={2}
          spacing={2}
        >
          <Button size="large" type="submit">
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default NewUser;
