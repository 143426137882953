import React from "react";
import { Avatar as MuiAvatar, SxProps, Theme } from "@mui/material";
import {
  grey,
  orange,
  lightBlue,
  purple,
  teal,
  red,
  brown,
  indigo,
} from "@mui/material/colors";

const nameInitials = (name: string, lastName?: string): string =>
  (name[0] + (lastName?.[0] || "")).toUpperCase();

const randomColor = (str: string) => {
  const colors = [
    grey[500],
    orange[500],
    lightBlue[500],
    purple[500],
    teal[500],
    indigo[500],
    red[500],
    brown[500],
  ];
  const index = str.charCodeAt(0) % colors.length;
  return colors[index];
};

const Avatar = ({
  name,
  lastName,
  sx,
}: {
  name: string;
  lastName?: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <MuiAvatar
      sx={{
        color: grey[900],
        backgroundColor: randomColor(name),
        fontSize: "4em",
        lineHeight: 0,
        fontWeight: 400,
        ...sx,
      }}
    >
      {name && nameInitials(name, lastName)}
    </MuiAvatar>
  );
};

export default Avatar;
