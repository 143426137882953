import React, { useEffect, useRef, useState } from "react";
import InnerHeader from "../../elements/InnerHeader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, TextField, Typography } from "@mui/material";
import NewTask from "./components/NewTask";
import Task from "./components/Task";
import ApiClient from "../../api/axios.config";
import { ApiStory, ApiTask } from "../../api/schema";
import { css } from "@emotion/react";
import { grey } from "@mui/material/colors";
import ViewTask from "./components/ViewTask";
import { useStore } from "../../store";
import { Params } from "wouter";
import Filters from "./components/Filters";

export type Status = "TODO" | "IN_PROGRESS" | "CODE_REVIEW" | "DONE";

const gridColumnStyle = {
  bgcolor: "grey.900",
  height: "100%",
  display: "flex",
  flexDirection: "column",
};

const skeletonStyle = css`
  background-color: ${grey["800"]};
  margin: 10px 20px;
  border-radius: 4px;
`;

const TasksSkeleton = ({ count = 4 }) => (
  <>
    {Array(count)
      .fill(0)
      .map((_, i) => (
        <Skeleton
          key={i}
          css={skeletonStyle}
          variant="rectangular"
          height={150}
        />
      ))}
  </>
);

const Tasks = ({ params }: { params: Params }) => {
  let { current: dragTaskId } = useRef<number | null>(null);
  let { current: dragFrom } = useRef<{
    position?: number;
    column?: Status;
  } | null>(null);
  let { current: dragTo } = useRef<{
    position?: number;
    column?: Status;
  } | null>(null);

  const [showModalNewTask, setShowModalNewTask] = useState(false);
  const [showModalViewTask, setShowModalViewTask] = useState<{
    show: boolean;
    task: ApiTask | null;
  }>({ show: false, task: null });
  const queryClient = useQueryClient();

  const archiveMode = useStore((state) => state.archiveMode)

  const { data, status } = useQuery({
    queryKey: ["tasks"],
    queryFn: () => ApiClient.tasks.listAllTasks().then((res) => res.data),
  });

  const { data: archivedTasks } = useQuery({
    queryKey: ["archivedTasks"],
    queryFn: () => ApiClient.tasks.listAllTasks({ archived: true }).then((res) => res.data),
  });

  const { data: stories } = useQuery({
    queryKey: ["stories"],
    queryFn: () => ApiClient.stories.listAllStories().then((res) => res.data),
  });

  const { data: archivedStories } = useQuery({
    queryKey: ["archivedStories"],
    queryFn: () => ApiClient.stories.listAllStories({ archived: true }).then((res) => res.data),
  });

  useEffect(() => {
    const updatedTask = data?.find(task => task.taskId === showModalViewTask.task?.taskId) || null;
    const isShowed = showModalViewTask.show
    setShowModalViewTask({ show: isShowed, task: updatedTask });
  }, [stories, data, archiveMode]);

  const { mutate } = useMutation({
    mutationFn: (variables: { taskId: number; status: Status, hoursSpent?: number }) =>
      ApiClient.tasks.updateStatus(variables.taskId, variables.status, variables?.hoursSpent),
    onMutate: async (taskOpt) => {
      await queryClient.cancelQueries(["tasks"]);
      const previousTasks = archiveMode
        ? queryClient.getQueryData<ApiTask[]>(["archivedTasks"])
        : queryClient.getQueryData<ApiTask[]>(["tasks"])


      if (previousTasks) {
        queryClient.setQueryData<ApiTask[]>(
          ["tasks"],
          previousTasks.map((task) =>
            task.taskId === taskOpt.taskId
              ? { ...task, status: taskOpt.status }
              : task
          )
        );
      }

      return { previousTasks };
    },
    onError: (err, variables, context) => {
      if (context?.previousTasks) {
        queryClient.setQueryData(["tasks"], context.previousTasks);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
    },
  });

  const tasks = (archiveMode ? archivedTasks : data)
    ?.filter((task) => {
      if (params.storyId) {
        return task.story.storyId === Number(params.storyId);
      }
      return task
    })
    .reduce((acc, object): Record<Status, ApiTask[]> => {
      if (object.status) {
        const status = object.status!;
        acc[status] = acc[status] || [];
        acc[status].push(object);
      }
      return acc;
    }, {} as Record<Status, ApiTask[]>)

  const handleCloseModal = () => {
    setShowModalNewTask(false);
    setShowModalViewTask({ show: false, task: null });
  };

  const handleDragStart = (
    event: any,
    taskId: number,
    column: Status,
    position: number
  ) => {
    dragTaskId = taskId;
    dragFrom = { column, position };
  };

  const handleDragEnd = (event: any) => {};

  const handleDragEnterColumn = (event: any, column: Status) => {
    dragTo = { ...dragTo, column };
  };

  const handleDragEnterPosition = (event: any, position: number) => {
    dragTo = { ...dragTo, position };
  };

  const handleDrop = (event: any) => {
    if (
      dragTaskId &&
      (dragFrom?.column !== dragTo?.column ||
        dragFrom?.position !== dragTo?.position)
    ) {
      if (dragTo?.column === "DONE") {
        setHoursSpentInput(
          tasks?.[dragFrom?.column!]?.find(({ taskId }) => (taskId === dragTaskId))?.estimatedHours || 0
        );
        setOpenDialog(dragTaskId);
      } else {
        mutate({ taskId: dragTaskId, status: dragTo?.column! });
      }
    }
    dragFrom = null;
    dragTo = null;
  };

  const handleEditTask = (event: any, task: ApiTask) => {
    setShowModalViewTask({ show: true, task });
  };

  const [openDialog, setOpenDialog] = useState<number | null>(null);
  const [hoursSpentInput, setHoursSpentInput] = useState(0);

  const handleClose = () => {
    setHoursSpentInput(0);
    setOpenDialog(null);
  };

  const handleConfirm = () => {
    mutate({ taskId: openDialog!, status: "DONE", hoursSpent: hoursSpentInput});
    handleClose();
  };

  return (
    <>
      <Dialog
        open={Boolean(openDialog)}
        onClose={handleClose}
      >
        <DialogTitle>
          How many hours have you spent on this task?
        </DialogTitle>
        <DialogContent>
          <TextField
            type="numeric"
            value={hoursSpentInput}
            onChange={(event: any) => {
              setHoursSpentInput(event.target.value);
            }}
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color='success' fullWidth onClick={handleConfirm}>Confirm</Button>
          <Button color='error' fullWidth onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <InnerHeader
        title="Tasks"
        onNewProject={() => setShowModalNewTask(true)}
        withoutMargin
      />
      <Filters stories={archiveMode ? archivedStories : stories} />
      <Grid container spacing={2} sx={{ minHeight: 800 }}>
        <Grid item xs={3}>
          <Box
            sx={gridColumnStyle}
            onDragEnter={(event) => handleDragEnterColumn(event, "TODO")}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              To do
            </Typography>
            {status === "loading" && <TasksSkeleton count={3} />}
            {tasks?.["TODO"]?.map(({ taskId, ...rest }, index) => (
              <Task
                index={index}
                key={taskId}
                task={{ taskId, ...rest }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragEnter={handleDragEnterPosition}
                onClick={handleEditTask}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={gridColumnStyle}
            onDragEnter={(event) => handleDragEnterColumn(event, "IN_PROGRESS")}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              In progress
            </Typography>
            {status === "loading" && <TasksSkeleton count={2} />}
            {tasks?.["IN_PROGRESS"]?.map(({ taskId, ...rest }, index) => (
              <Task
                index={index}
                key={taskId}
                task={{ taskId, ...rest }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragEnter={handleDragEnterPosition}
                onClick={handleEditTask}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={gridColumnStyle}
            onDragEnter={(event) => handleDragEnterColumn(event, "CODE_REVIEW")}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              Code review
            </Typography>
            {status === "loading" && <TasksSkeleton count={1} />}
            {tasks?.["CODE_REVIEW"]?.map(({ taskId, ...rest }, index) => (
              <Task
                index={index}
                key={taskId}
                task={{ taskId, ...rest }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragEnter={handleDragEnterPosition}
                onClick={handleEditTask}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={gridColumnStyle}
            onDragEnter={(event) => handleDragEnterColumn(event, "DONE")}
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
          >
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              Done
            </Typography>
            {status === "loading" && <TasksSkeleton count={4} />}
            {tasks?.["DONE"]?.map(({ taskId, ...rest }, index) => (
              <Task
                index={index}
                key={taskId}
                task={{ taskId, ...rest }}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragEnter={handleDragEnterPosition}
                onClick={handleEditTask}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <NewTask
        stories={stories}
        open={showModalNewTask}
        onClose={handleCloseModal}
      />
      {showModalViewTask.task && (
        <ViewTask
          open={showModalViewTask.show}
          task={showModalViewTask.task}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Tasks;
