import React, { SyntheticEvent } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { ApiUser } from "../../../api/schema";
import Avatar from "../../../elements/Avatar";

interface UserItem {
  onClick: (evt: SyntheticEvent) => void;
}

const UserItem = ({
  name,
  lastName,
  role,
  userId,
  email,
  onClick,
}: UserItem & Partial<ApiUser>) => {
  return (
    <Card
      sx={{
        minHeight: 310,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onClick={onClick}
    >
      <CardActionArea sx={{ flex: 1 }}>
        <CardContent>
          {/*<AccountCircleIcon*/}
          {/*  sx={{*/}
          {/*    fontSize: 140,*/}
          {/*    marginLeft: "auto",*/}
          {/*    marginRight: "auto",*/}
          {/*    display: "block",*/}
          {/*    marginBottom: 2,*/}
          {/*    fill: "#757575",*/}
          {/*  }}*/}
          {/*/>*/}
          <Avatar
            name={name!}
            lastName={lastName}
            sx={{
              width: "140px",
              height: "140px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 2,
            }}
          />
          <Typography
            align="center"
            variant="h5"
            component="h3"
            overflow="hidden"
          >
            {name}
          </Typography>
          {role && (
            <Typography
              align="center"
              variant="body2"
              color="text.secondary"
              mt={1}
            >
              {role}
            </Typography>
          )}
          {email && (
            <Typography
              align="center"
              variant="body2"
              color="text.secondary"
              mt={1}
            >
              {email}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default UserItem;
