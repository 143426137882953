import React, {
  SyntheticEvent,
  useCallback,
  useReducer,
  useState,
} from "react";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { getState, useStore } from "../../store";
import { ApiUserDto } from "../../api/schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../api/axios.config";
import Avatar from "../../elements/Avatar";
import { BaseTextFieldProps } from "@mui/material/TextField/TextField";

const Profile = () => {
  const user = useStore((state) => state.user);

  const {
    name,
    lastName,
    email,
    username,
    role,
    employmentStatus,
  } = user || {};

  const initialFormState = {
    name,
    lastName,
    email,
    username,
    role,
    employmentStatus,
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation((payload: ApiUserDto) =>
    ApiClient.profile.updateProfile(payload)
  );

  const [formInput, setFormInput] = useReducer(
    (state: ApiUserDto, newState: Partial<ApiUserDto>) => ({
      ...state,
      ...newState,
    }),
    initialFormState
  );

  const [inputBeforeChange, setInputBeforeChange] =
    useState<Partial<ApiUserDto>>(initialFormState);

  const [submitTimeout, setSubmitTimeout] = useState<{
    input: {
      name: null | string;
      status: Partial<BaseTextFieldProps["color"]> | null;
    };
    inputTimeout: null | NodeJS.Timeout;
  }>({
    input: { name: null, status: null },
    inputTimeout: null,
  });

  const submit = useCallback(
    (newInput: Partial<ApiUserDto>) => {
      setSubmitTimeout((prev) => ({
        ...prev,
        input: { ...prev.input, status: "info" },
      }));

      mutate(
        { ...formInput, ...newInput },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            setSubmitTimeout((prev) => ({
              ...prev,
              input: { ...prev.input, status: "success" },
            }));
            getState().setNotification("Profile saved", "success");
          },
          onError: () => {
            setFormInput(inputBeforeChange);
            setSubmitTimeout((prev) => ({
              ...prev,
              input: { ...prev.input, status: "error" },
            }));
          },
        }
      );
    },
    [formInput, inputBeforeChange]
  );

  const handleInput = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    if (submitTimeout.inputTimeout) {
      clearTimeout(submitTimeout.inputTimeout);
      setSubmitTimeout({
        input: { name: null, status: null },
        inputTimeout: null,
      });
    }
    const target = evt.target as HTMLInputElement;
    const name = target.name;
    const newValue = target.value;

    setFormInput({ [name]: newValue });

    setSubmitTimeout({
      input: { name, status: null },
      inputTimeout: setTimeout(() => submit({ [name]: newValue }), 1000),
    });
  };

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const changePassword = (event: SyntheticEvent) => {
    if (oldPassword && newPassword && newPasswordConfirm) {
      if (newPassword === newPasswordConfirm) {
        ApiClient.profile.changeUserPassword(newPassword, oldPassword).then((res) => {
          getState().setNotification(res.data, "success");
          setOldPassword('');
          setNewPassword('');
          setNewPasswordConfirm('');
        }).catch((error) => {
          getState().setNotification(error.response.data, "error");
        })
      } else {
        getState().setNotification("New passwords don't match", "error");
      }
    } else {
      getState().setNotification("Fill all the fields", "error");
    }
  }

  const [profileMode, setProfileMode] = useState('general');
  const handleProfileMode = (event: SyntheticEvent, newMode: string) => {
    if (newMode !== null) {
      setProfileMode(newMode);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack direction='column' spacing={1}>
        <Typography variant="h5">Profile</Typography>
        <Tabs
          value={profileMode}
          onChange={handleProfileMode}
          variant="fullWidth"
          sx={{ maxWidth: 300 }}
        >
          <Tab value='general' label="General" />
          <Tab value='security' label="Security" />
        </Tabs >
      </Stack>

      {profileMode === 'general' ?
        <Stack direction="row" spacing={5}>
          <Box sx={{ flex: "1 1 50%" }}>
            <TextField
              color={
                submitTimeout.input.name === "name"
                  ? submitTimeout.input.status!
                  : "primary"
              }
              label="Name"
              variant="outlined"
              fullWidth
              value={formInput.name}
              margin="normal"
              inputProps={{ maxLength: 100 }}
              name="name"
              onInput={handleInput}
              onFocus={() => setInputBeforeChange(formInput)}
            />

            <TextField
              color={
                submitTimeout.input.name === "lastName"
                  ? submitTimeout.input.status!
                  : "primary"
              }
              label="Last name"
              variant="outlined"
              fullWidth
              value={formInput.lastName}
              margin="normal"
              inputProps={{ maxLength: 100 }}
              name="lastName"
              onInput={handleInput}
              onFocus={() => setInputBeforeChange(formInput)}
            />

            <TextField
              color={
                submitTimeout.input.name === "email"
                  ? submitTimeout.input.status!
                  : "primary"
              }
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              value={formInput.email}
              margin="normal"
              inputProps={{ maxLength: 100 }}
              name="email"
              onInput={handleInput}
              onFocus={() => setInputBeforeChange(formInput)}
            />

            <TextField
              color={
                submitTimeout.input.name === "username"
                  ? submitTimeout.input.status!
                  : "primary"
              }
              label="Username"
              variant="outlined"
              fullWidth
              value={formInput.username}
              margin="normal"
              inputProps={{ maxLength: 100 }}
              name="username"
              onInput={handleInput}
              onFocus={() => setInputBeforeChange(formInput)}
            />

            <TextField
              select
              label="Role"
              name="role"
              disabled
              defaultValue="Developer"
              value={formInput.role}
              onChange={handleInput}
              fullWidth
              margin="normal"
            >
              {["Admin", "Manager", "Developer"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              disabled
              label="Employment Status"
              name="employmentStatus"
              defaultValue="FullTime"
              value={formInput.employmentStatus}
              onChange={handleInput}
              fullWidth
              margin="normal"
            >
              {["FullTime", "Contractor"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              flex: "1 0 50%",
              ml: "auto",
              mr: "40px!important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              name={name}
              lastName={lastName}
              sx={{
                width: 160,
                height: 160,
              }}
            />
          </Box>
        </Stack>
        : <Stack direction="column" spacing={3} sx={{ maxWidth: 400 }}>
          <Typography variant="h6">
            Update Password
          </Typography>

          <TextField
            label="Old password"
            variant="outlined"
            fullWidth
            value={oldPassword}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
          />
          <TextField
            label="New password"
            variant="outlined"
            fullWidth
            value={newPassword}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
          />
          <TextField
            label="Confirm new password"
            variant="outlined"
            fullWidth
            value={newPasswordConfirm}
            onInput={(event: React.ChangeEvent<HTMLInputElement>) => setNewPasswordConfirm(event.target.value)}
          />
          <Button onClick={changePassword} variant='contained' color='error'>Change</Button>
        </Stack>
      }
    </Stack>
  );
};

export default Profile;
