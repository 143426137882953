import { useMemo } from "react";
import DataGrid, {
  Column,
  EditorProps,
  FormatterProps,
  RowsChangeData,
} from "react-data-grid";
import { Row, SummaryRow, TableType } from "../Finance";
import { TableTextEditor } from "../../../elements/TableTextEditor";
import { tablesDefaults } from "../tableDefaults";
import { CustomFormatter } from "../../../elements/CustomFormatter";
import SelectFormatter from "../../../elements/SelectFormatter";
import { css } from "@emotion/react";
import { formatNumber } from "../../../helpers/helpers";
import { getState } from "../../../store";

// Key getter simply doesn't work!!!!!
function rowKeyGetter(row: Row) {
  return row.id;
}

const dataGridStyles = css`
  height: auto;

  & + .rdg {
    margin-top: 40px;
  }

  .rdg-summary-row {
    background-color: var(--rdg-header-background-color);
  }
`;

const Table = ({
  outerTableName,
  table,
  innerTableName,
  onTableChange,
  disabled,
}: {
  outerTableName?: string;
  table: TableType;
  innerTableName: string;
  onTableChange: (
    rows: Row[],
    data: RowsChangeData<Row, SummaryRow>,
    tableName: string
  ) => void;
  disabled: boolean;
}) => {
  const tableName = outerTableName || innerTableName;
  const defaultColumn = (name: string, key: string) =>
    tablesDefaults[name]?.columns?.[key];

  const withSummary = ["Task Table", "Story Table", "Reserve Table", "Payout Table"].includes(
    tableName
  );

  const store = getState();

  const handleRowsChange = (
    rows: Row[],
    data: RowsChangeData<Row, SummaryRow>
  ) => {
    onTableChange(rows, data, innerTableName);
  };

  const columnsWithEditor = table.columns.map((column, columnIndex) => {
    const defaultValue = defaultColumn(tableName, column.key);
    return {
      ...column,
      editable:
        (
          (columnIndex > 0 &&
            (defaultValue?.editable ?? !Boolean(defaultValue?.formula)) &&
            !disabled) && (
            column.key === "budget" ?
            (table.rows[0].projectOwnerId === store.user.userId ||
              store.user.role === "Admin") : 1
          )
        ),
      editor: (args: EditorProps<Row, unknown>) =>
        TableTextEditor({
          ...args,
          tableName,
          stripNumbers: columnIndex !== 0,
          formatter: defaultValue?.formatter,
        }),
      formatter: (args: FormatterProps<Row, unknown>) =>
        defaultValue?.type === "checkbox"
          ? SelectFormatter({ ...args, columnKey: column.key })
          : CustomFormatter({
            ...args,
            tableName,
            formatter: defaultValue?.formatter,
          }),
      ...(withSummary
        ? {
          summaryFormatter({ row }: { row: SummaryRow }) {
            return <strong>{row[column.key]}</strong>;
          },
        }
        : {}),
    };
  }) as Column<Row, SummaryRow>[];

  const summaryRows = useMemo((): SummaryRow[] => {
    const sum = (byKey: string) =>
      table.rows?.reduce((acc: number, value: any) => acc + value[byKey], 0);

    return withSummary
      ? [
        {
          task: "Total",
          reserveManagement: "Total",
          story: "Total",
          user: "Total",
          weightPercentage: formatNumber(sum("weightPercentage"), "percent"),
          verifiedWeightPercentage: formatNumber(sum("verifiedWeightPercentage"), "percent"),
          potentialReward: formatNumber(sum("potentialReward"), "currency"),
          estimatedTaskHours: sum("estimatedTaskHours"),
          developerSubmittedHours: sum("developerSubmittedHours"),
          adjustedHours: sum("adjustedHours"),
          actualReward: formatNumber(sum("actualReward"), "currency"),
          actualRewardWithBonus: formatNumber(sum("actualRewardWithBonus"), "currency"),
          totalReward: formatNumber(sum("totalReward"), "currency"),
        },
      ]
      : [];
  }, [table.rows]);

  return (
    <DataGrid
      css={dataGridStyles}
      rowKeyGetter={rowKeyGetter}
      columns={columnsWithEditor}
      rows={table.rows}
      onRowsChange={handleRowsChange}
      bottomSummaryRows={summaryRows}
      defaultColumnOptions={{
        sortable: true,
      }}
    />
  );
};

export default Table;
