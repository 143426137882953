import { Router, useLocation, useRouter } from "wouter";
import { Path } from "wouter/types/use-location";
import { FC, ReactNode } from "react";

const NestedRoutes = ({
  base,
  children,
  Wrap,
}: {
  base: Path;
  children: ReactNode;
  Wrap: FC<{ children: ReactNode }>;
}) => {
  const router = useRouter();
  const [parentLocation] = useLocation();

  const nestedBase = `${router.base}${base}`;

  if (!parentLocation.startsWith(nestedBase)) return <></>;

  return (
    <Router base={nestedBase} key={nestedBase}>
      <Wrap>{children}</Wrap>
    </Router>
  );
};

export default NestedRoutes;
