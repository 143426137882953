import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  SelectChangeEvent,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useQuery, useQueryClient, QueryClient } from '@tanstack/react-query';
import { useEffect, useState } from "react";
import ApiClient from "../../../api/axios.config";
import { ApiRequest } from '../../../api/schema';
import Avatar from "../../../elements/Avatar";
import { useStore } from "../../../store";

const RequestsSkeleton = () => {
  return (
    <Stack direction='column' spacing={2}>
      {[...Array(5)
        .keys()]
        .map((i) => (
          <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack direction='row' spacing={2}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
            </Stack>
            <Stack direction='row' spacing={2}>
              <Skeleton variant="rounded" width={100} height={40} />
              <Skeleton variant="rounded" width={100} height={40} />
            </Stack>
          </Box>
        ))}
    </Stack>
  );
}

const Requests = () => {
  const archiveMode = useStore((state) => state.archiveMode);
  const queryClient = useQueryClient();
  const [nameFilter, setNameFilter] = useState<string>('');
  const [selectedRequestsType, setSelectedRequestsType] = useState<string | null>(null);
  const handleSelectRequestsType = (event: React.MouseEvent<HTMLElement>, requestsType: string | null) => {
    setSelectedRequestsType(requestsType);
  };

  const handleNameFilterChange = (e: { target: { value: string; }; }) => {
    setNameFilter(e.target.value);
  }

  const handleClearNameFilter = () => setNameFilter('');

  const [selectedProject, setSelectedProject] = useState('');
  const selectProject = (event: SelectChangeEvent) => {
    setSelectedProject(event.target.value);
  };

  const { data: requests, status: statusRequests } = useQuery({
    queryKey: ["requests"],
    queryFn: () => ApiClient.requests.getRequests().then((res) => res.data)
  });
  const [filteredRequests, setFilteredRequests] = useState<ApiRequest[]>([]);

  const filter = () => {
    let requestsFilter = requests!;
    if (selectedRequestsType) {
      requestsFilter = requestsFilter.filter(request => {
        if (selectedRequestsType === request.requestType) {
          return request;
        }
      });
    }
    if (nameFilter) {
      requestsFilter = requestsFilter.filter(request => {
        if ((request.taskName || request.storyName || request.projectName).includes(nameFilter)) {
          return request;
        }
      });
    }
    setFilteredRequests(requestsFilter)
    if (!selectedRequestsType && !nameFilter) {
      setFilteredRequests([]);
    }
  }

  const clearFilters = () => {
    setFilteredRequests([]);
  }

  useEffect(() => {
    filter();
  }, [selectedRequestsType, nameFilter])

  const Approve = async (requestId: number) => {
    await ApiClient.requests.approve(requestId).then(() => {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    });
  }

  const Deny = async (requestId: number) => {
    await ApiClient.requests.deny(requestId).then(() => {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    });
  }

  return (
    <Paper>
      <Box>
        <Box
          component="form"
          aria-label="filters"
          noValidate
          autoComplete="off"
          sx={{ padding: 2, gap: 2, display: 'flex', justifyContent: 'space-between' }}
        >
          <ToggleButtonGroup
            color="primary"
            exclusive
            aria-label="selectRequestsType"
            value={selectedRequestsType}
            onChange={handleSelectRequestsType}
            sx={{
              height: 50
            }}
          >
            <ToggleButton value="JOIN" aria-label="join" sx={{ border: 0, borderRadius: 1 }}>
              Join Project
            </ToggleButton>
            <ToggleButton value="LEAD" aria-label="lead" sx={{ border: 0 }}>
              Lead Project
            </ToggleButton>
            <ToggleButton value="ASSIGN_STORY" aria-label="assign story" sx={{ border: 0 }}>
              Story
            </ToggleButton>
            <ToggleButton value="ASSIGN_TASK" aria-label="assign task" sx={{ border: 0 }}>
              Task
            </ToggleButton>
          </ToggleButtonGroup>
          <OutlinedInput
            placeholder="Enter entity name"
            value={nameFilter}
            onChange={handleNameFilterChange}
            sx={{ display: 'flex', height: 50 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="filter by name"
                  onClick={handleClearNameFilter}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        <Divider />
        <Box sx={{ padding: 3 }} aria-label="requests">
          {statusRequests === 'loading' ? <RequestsSkeleton /> : <>
            {(requests?.length && !archiveMode) ?
              <Stack spacing={2}>
                {((selectedRequestsType || nameFilter) ? filteredRequests : requests).map((request) => {
                  return (
                    <Box key={request.requestId} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Stack direction='column' spacing={1}>
                        <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar name={request.userName} sx={{ fontSize: "1.4em", width: 40, height: 40 }} />
                          <Box sx={{ display: 'flex' }}>
                            <Typography>
                              {request.userName}
                            </Typography>
                            &nbsp;
                            <Typography>
                              wants to
                            </Typography>
                            &nbsp;
                            {request.requestType === 'JOIN' && <>
                              <Typography color='yellow'>join</Typography> &nbsp;
                              <Typography>project:</Typography> &nbsp;
                            </>}
                            {request.requestType === 'LEAD' && <>
                              <Typography color='secondary'>lead</Typography> &nbsp;
                              <Typography>project:</Typography> &nbsp;
                            </>}
                            {request.requestType === 'ASSIGN_STORY' && <>
                              <Typography color='#00b0ff'>be assigned</Typography> &nbsp;
                              <Typography>to story:</Typography> &nbsp;
                            </>}
                            {request.requestType === 'ASSIGN_TASK' && <>
                              <Typography color='cyan'>be assigned</Typography> &nbsp;
                              <Typography>to task:</Typography> &nbsp;
                            </>}
                            {request.taskName || request.storyName || request.projectName}
                          </Box>
                        </Stack>
                        {request.motivation &&
                          <Typography variant='subtitle2'>
                            motivation:
                            {' ' + request.motivation}
                          </Typography>
                        }
                      </Stack>
                      <Stack direction='row' spacing={2}>
                        <Button variant="contained" color="success" onClick={() => Approve(request.requestId)}
                          sx={{ width: 100, height: 40, color: 'white' }}>
                          Approve
                        </Button>
                        <Button variant="contained" color="error" onClick={() => Deny(request.requestId)}
                          sx={{ width: 100, height: 40, color: 'white' }}>
                          Deny
                        </Button>
                      </Stack>
                    </Box>
                  );
                })}
                {((selectedRequestsType || nameFilter) && !filteredRequests.length) &&
                  <Box>
                    No requests
                  </Box>
                }
              </Stack> :
              <Box>
                {archiveMode ? 'No available requests in archived view' : 'No requests'}
              </Box>
            } </>
          }
        </Box>
      </Box>
    </Paper>
  );
};

export default Requests;
