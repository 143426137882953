import React, { SyntheticEvent, useEffect, useState } from "react";
import { Badge, MenuItem, Stack, TextField } from "@mui/material";
import { ApiStory } from "../../../api/schema";
import { useLocation, useRoute } from "wouter";

const Filters = ({ stories }: { stories?: ApiStory[] }) => {
  const [location, setLocation] = useLocation();
  const [match, params] = useRoute("tasks/:storyId");
  const [selectedStory, setSelectedStory] = useState(params?.storyId || "all");

  useEffect(() => {
    if (!params?.storyId) {
      setSelectedStory("all");
    }
  }, [params?.storyId]);

  const handleSelectStory = (evt: SyntheticEvent<any>) => {
    evt.preventDefault();
    const target = evt.target as HTMLInputElement;
    const newValue = target.value;

    setSelectedStory(newValue);
    if (newValue === "all") {
      setLocation(`tasks`);
      return;
    }
    setLocation(`tasks/${newValue}`);
  };

  return (
    <Stack direction="row" marginBottom={4} spacing={2}>
      {stories && (
        <TextField
          select
          label="Story"
          name="story"
          defaultValue={0}
          value={selectedStory}
          onChange={handleSelectStory}
          margin="normal"
          size="small"
          sx={{ width: "160px" }}
          SelectProps={{
            renderValue: (selected) => {
              return (
                stories.find((s) => s.storyId === Number(selectedStory))
                  ?.title || "All"
              );
            },
          }}
        >
          {[{ storyId: "all", title: "All", tasks: null }, ...stories].map(
            (story) => (
              <MenuItem key={story.storyId} value={story.storyId}>
                {story.title}
                <Badge
                  badgeContent={story.tasks?.length}
                  color="primary"
                  sx={{
                    width: "20px",
                    marginLeft: "auto",
                  }}
                />
              </MenuItem>
            )
          )}
        </TextField>
      )}
    </Stack>
  );
};

export default Filters;
